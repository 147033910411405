const remChange = function () {
  let timer = null
  const PAGE_WIDTH = 375 // 设计稿的宽度
  const PAGE_FONT_SIZE = 100 // 设计稿1rem的大小

  function onResize() {
    // if (document.documentElement.clientWidth > 450) {
    // // 大屏下减小根元素字体
    //   PAGE_FONT_SIZE = 50
    // }
    // 小屏幕下根元素字体随视口宽度变化
    let e = (PAGE_FONT_SIZE * document.documentElement.clientWidth) / PAGE_WIDTH
    document.documentElement.style.fontSize = e + 'px'
    // 二次计算缩放像素，解决移动端webkit字体缩放bug
    const realitySize = parseFloat(window.getComputedStyle(document.documentElement).fontSize)
    if (e !== realitySize) {
      e = (e * e) / realitySize
      document.documentElement.style.fontSize = e + 'px'
    }
  }
  window.addEventListener('resize', function () {
    if (timer) clearTimeout(timer)
    timer = setTimeout(onResize, 100)
  })
  onResize()
}

export default remChange
