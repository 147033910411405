<template>
  <div id="loginView">
    <div style="margin-top: 0.72rem; color: #333; font-size: 0.24rem; font-weight: bold; text-align: center">
      账号密码登录
    </div>
    <div
      style="
        margin-top: 0.24rem;
        color: darkgray;
        font-size: 0.12rem;
        font-weight: 300;
        text-align: center;
        height: 0.16rem;
      "
    >
      使用已注册的账号登录
    </div>
    <div style="margin-top: 0.03rem; color: darkgray; font-size: 0.12rem; font-weight: 300; text-align: center">
      账号默认为您注册的手机号
    </div>
    <div style="width: 90%; margin-left: 5%; margin-top: 0.56rem">
      <div
        style="
          background: #f5f7f7;
          width: 90%;
          margin-left: 5%;
          height: 0.48rem;
          line-height: 0.48rem;
          font-size: 0.15rem;
          border-radius: 0.24rem;
        "
      >
        <el-row>
          <el-col :span="4">
            <div style="font-weight: bold; width: 100%; height: 0.48rem; text-align: center">+86</div>
          </el-col>
          <el-col :span="20">
            <input
              placeholder="请输入手机号"
              type="text"
              pattern="d*"
              @input="handleInput"
              v-model="account.account"
              style="border: 0 solid #fff; height: 0.32rem; line-height: 0.32rem; background-color: #f5f7f7"
            />
          </el-col>
        </el-row>
        <el-row
          style="
            background: #f5f7f7;
            margin-top: 0.24rem;
            height: 0.48rem;
            line-height: 0.48rem;
            font-size: 0.15rem;
            border-radius: 0.24rem;
          "
        >
          <el-col :span="4" style="height: 0.32rem; line-height: 0.48rem">
            <div style="float: right; margin-right: 7px">密码</div>
          </el-col>
          <el-col :span="20">
            <div :span="2" style="color: #7a7a7a; margin-left: 0px">
              <input
                placeholder="请输入密码"
                type="password"
                style="border: 0 solid #fff; height: 0.32rem; line-height: 0.32rem; background-color: #f5f7f7"
                v-model="account.password"
              />
            </div>
          </el-col>
        </el-row>
      </div>

      <div style="margin-top: 1.2rem">
        <div style="margin-bottom: 0.24rem">
          <el-checkbox v-model="checked" shape="square">
            我已经阅读并同意
            <span style="color: #00d5b8" @click.stop="$router.push('sijoyService')">《服务协议》</span>
            和
            <span style="color: #00d5b8" @click.stop="$router.push('sijoyPrivacy')">《隐私政策》</span>
          </el-checkbox>
        </div>
        <el-button
          style="background: #266eff; width: 95%; height: 0.48rem; border-radius: 0.24rem; font-size: 0.14rem"
          type="primary"
          @click="login"
        >
          登录
        </el-button>
      </div>

      <div class="bottom">
        <div @click="setNewPass()">忘记密码?</div>
        <div class="line"></div>
        <div @click="goToCodeLogin">使用验证码登录</div>
      </div>
    </div>

    <!-- 医院列表 -->
    <!-- <van-dialog
      v-model="hospitalDialog"
      class="hospitalDialog"
      title="选择医院"
      showConfirmButton
      confirmButtonText="关闭"
    >
      <div
        class="dialog-box"
        v-for="(item, index) in hospitalList"
        :key="index"
        @click="hospitalClick(item)"
      >
        {{ item.struct_name }}
      </div>
    </van-dialog> -->

    <!-- 闭环类型 -->
    <van-dialog
      v-model="hospitalDialog"
      class="hospitalDialog"
      title="选择闭环类型"
      showConfirmButton
      confirmButtonText="关闭"
    >
      <div class="dialog-box" @click="hospitalClick('loop')">loop</div>
      <div class="dialog-box" @click="hospitalClick('aaps')">aaps</div>
    </van-dialog>

    <!-- 设置新密码 -->
    <van-dialog v-model="setNewPassShow" title="忘记密码" show-cancel-button @confirm="confirmPassword">
      <van-cell-group>
        <van-field
          v-model.trim="setPassForm.phone"
          label="手机号"
          :rules="[
            { required: true, message: '请填写手机号码', trigger: 'blur' },
            { validator: validatePhone, message: '请输入有效的手机号码' },
          ]"
        />
        <van-field
          v-model.trim="setPassForm.password"
          label="新密码"
          :rules="[{ required: true, message: '请填写新密码', trigger: 'blur' }]"
        />
        <van-field
          v-model.trim="setPassForm.code"
          label="验证码"
          :rules="[{ required: true, message: '请填写验证码', trigger: 'blur' }]"
        >
          <van-button slot="button" size="small" type="primary" @click="getCode">
            {{ showGetCode ? '发送验证码' : timeOut + '秒后重试' }}
          </van-button>
        </van-field>
      </van-cell-group>
    </van-dialog>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { Toast } from 'vant'
  // eslint-disable-next-line no-unused-vars
  import { getCode, login } from '../../api/userServices'
  import { getHospitalByDoctor, getNewPassword } from '../../api/relationship'
  export default {
    name: 'LoginView',

    data() {
      return {
        checked: false,
        account: {
          account: '',
          password: '',
        },
        hospitalDialog: false,
        hospitalList: [],
        setNewPassShow: false,
        setPassForm: {
          phone: '',
          password: '',
          code: '',
        },
        showGetCode: true,
        timeOut: 120,
      }
    },

    mounted() {
      const loginStatus = JSON.parse(localStorage.getItem('loginStatus'))
      if (loginStatus) {
        this.$router.push({ path: '/BloodSugar', query: { index: 0 } })
      }
    },

    methods: {
      // 选择闭环类型
      hospitalClick(name) {
        let data = JSON.parse(localStorage.getItem('appuser'))
        const newData = {
          ...data,
          loopRemote: name,
        }
        localStorage.setItem('appuser', JSON.stringify(newData))
        this.$ws.start()
        this.hospitalDialog = false
        this.$router.push({
          path: '/BloodSugar',
          query: { index: 0 },
        })
        Toast('登录成功！')
      },
      confirmPassword() {
        if (this.setPassForm.phone && this.setPassForm.password && this.setPassForm.code) {
          getNewPassword(this.setPassForm).then((res) => {
            if (res.data.code == 200) {
              this.$toast('修改成功')
            } else {
              this.$toast(res.data.msg)
            }
          })
        } else {
          this.$toast('请检查表单填写')
        }
      },
      getCode() {
        if (this.validatePhone(this.setPassForm.phone)) {
          this.showGetCode = false
          this.timeOut = 120
          getCode(this.setPassForm.phone)
          const _this = this
          this.timer = setInterval(function () {
            if (_this.timeOut > 1) {
              _this.timeOut = _this.timeOut - 1
            } else {
              _this.closeInterval()
            }
          }, 1000)
        } else {
          this.$toast('请正确填写手机号')
        }
      },
      closeInterval() {
        this.showGetCode = true
        clearInterval(this.timer)
      },
      validatePhone(value) {
        console.log(value)
        const phonePattern = /^1[3-9]\d{9}$/
        return phonePattern.test(value)
      },
      setNewPass() {
        this.setNewPassShow = true
      },
      handleInput(e) {
        let value = e.target.value + ''
        let regSpace = /\s/
        // 如果有空格，去掉空格
        if (regSpace.test(value)) {
          this.account.account = value.replace(/\s+/g, '')
          return
        }
        // 限制输入位数
        if (value.length > 11) {
          this.account.account = value.slice(0, 11)
        }
      },
      login() {
        if (!this.checked) {
          return this.$toast('请勾选并同意《服务协议》及《隐私政策》')
        } else if (!this.account.account) {
          return this.$toast('请输入手机号')
        } else if (!this.account.password) {
          return this.$toast('请输入密码')
        }
        const params = {
          phone: this.account.account,
          password: this.account.password,
          type: 2,
        }
        login(params)
          .then((res) => {
            const code = res.data.code
            if (code == 200 || code == 201) {
              const targetHigh = res.data.data.appuser.targetHigh ? res.data.data.appuser.targetHigh : '10'
              const targetLow = res.data.data.appuser.targetLow ? res.data.data.appuser.targetLow : '3.9'
              localStorage.setItem('targetHigh', targetHigh)
              localStorage.setItem('targetLow', targetLow)
              localStorage.setItem('token', res.data.data.token)
              localStorage.setItem('appuser', JSON.stringify(res.data.data.appuser))
              localStorage.setItem('loginStatus', true)
              const message = {
                token: res.data.data.token,
              }
              const androidMessage = JSON.stringify(message)
              try {
                const phoneType = localStorage.getItem('phoneType')
                if (phoneType == 'Android') {
                  window.android.setTokenToIOS(androidMessage)
                } else {
                  window.webkit.messageHandlers.setTokenToIOS.postMessage(message)
                }
              } catch (error) {}
              if (res.data.data.appuser.loopRemote == 'aapsloop') {
                this.hospitalDialog = true
              } else {
                this.$ws.start()
                this.$router.push({
                  path: '/BloodSugar',
                  query: { index: 0 },
                })
                Toast('登录成功！')
              }
            } else {
              Toast(res.data.msg || '登录失败')
              // this.$router.push('/register');
            }
          })
          .catch((error) => {
            Toast('登录失败')
          })
      },
      // 选择医院
      // hospitalClick(item) {
      //   localStorage.setItem("hospital", JSON.stringify(item));
      //   this.hospitalDialog = false;
      //   this.$router.push({
      //     path: "/BloodSugar",
      //     query: { index: 0 },
      //   });
      //   Toast("登录成功！");
      // },
      goToCodeLogin() {
        this.$router.push('/login')
      },
    },
  }
</script>
<style scoped lang="less">
  #loginView {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background: url('../../static/pic/loginBG.jpg') 0% 88%;
  }

  .inputValue {
    border: 1px solid #424141;
    height: 0.32rem;
    line-height: 0.32rem;
    padding-left: 12px;
    border-radius: 8px;
  }
  input,
  textarea {
    font-size: 16px;
  }

  ::v-deep .hospitalDialog {
    .van-dialog__header {
      padding-top: 10px;
      font-weight: 700;
    }
  }
  .dialog-box {
    align-items: center;
    background-color: #dbe3f8;
    border-bottom: 3px solid #b0bee4;
    border-radius: 0.045rem;
    color: #6372a5;
    display: flex;
    font-size: 0.13rem;
    font-weight: 600;
    height: 0.32rem;
    justify-content: center;
    overflow: hidden;
    padding: 0 0.12rem;
    text-decoration: none;
    transition: 0.2s ease-in-out;
    transition-property: background-color, box-shadow, border;
    width: 70%;
    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .dialog-box:hover {
    background-color: #b0bee4;
    border-color: #98a7d4;
    color: #485584;
    outline: none;
  }
  .bottom {
    font-size: 0.14rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.32rem;
    color: #409eff;
    .line {
      border-right: 1px solid #409eff;
      height: 0.15rem;
      margin: 0 0.24rem;
    }
  }
  ::v-deep .van-dialog__header {
    padding: 10px;
  }
  ::v-deep .van-button--primary {
    background-color: #409eff;
    border: 0;
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #00d5b8;
    border-color: #00d5b8;
  }
  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #00d5b8;
  }
  ::v-deep .el-checkbox__inner:hover {
    border-color: #00d5b8;
  }
  input {
    &::placeholder {
      font-size: 0.12rem;
    }
  }
</style>
