<template>
  <div id="EditDialog">
    <van-nav-bar v-if="registerType" title="个人信息" />
    <van-nav-bar v-else title="个人信息" left-arrow @click-left="goBackMineView" />
    <div class="info">常规设置</div>
    <div class="file">
      <div>
        <span>账号</span>
        <span style="margin-right: 0.18rem">
          {{ userInfo.iphone }}
        </span>
      </div>
      <div @click="showCode()">
        <span>我的二维码</span>
        <span class="flex">
          <img src="@/static/pic/qrIcon.png" alt="" style="width: 0.19rem; height: 0.18rem" />
          <van-icon name="arrow" style="margin-top: 0.01rem" />
        </span>
      </div>
      <div @click="openCamera()">
        <span>扫一扫</span>
        <span>
          <van-icon name="arrow" />
        </span>
      </div>
    </div>

    <div class="info">个人信息</div>

    <div class="file">
      <div @click="editAva(userInfo.images)">
        <span>头像</span>
        <span>
          <img
            :src="
              !userInfo.images || userInfo.images == '未设置'
                ? 'https://i.postimg.cc/2SrXwLXR/litangbao.png'
                : userInfo.images
            "
            style="width: 0.32rem; height: 0.32rem; border-radius: 50%"
          />
          <van-icon name="arrow" />
        </span>
      </div>

      <div @click="nameShow = true">
        <span>昵称</span>
        <span>
          {{ userInfo.name ? userInfo.name : '无' }}
          <van-icon name="arrow" />
        </span>
      </div>

      <div @click="areaShow = true">
        <span>地区</span>
        <span>
          {{ userInfo.city ? userInfo.city : '无' }}
          <van-icon name="arrow" />
        </span>
      </div>

      <div @click="passwordShow = true">
        <span>密码</span>
        <span>
          ******
          <van-icon name="arrow" />
        </span>
      </div>

      <div @click="heightShow = true">
        <span>身高</span>
        <span>
          {{ userInfo.height + ' cm' }}
          <van-icon name="arrow" />
        </span>
      </div>

      <div @click="weightShow = true">
        <span>体重</span>
        <span>
          {{ userInfo.weight + ' kg' }}
          <van-icon name="arrow" />
        </span>
      </div>
    </div>
    <div class="info">账号管理</div>
    <div class="zhuxiao" @click="logOff">
      <span>注销账号</span>
      <span>
        <van-icon name="arrow" />
      </span>
    </div>

    <div style="margin: 0.12rem" v-if="registerType">
      <van-button round block type="info" native-type="submit" @click="goSugar">提交</van-button>
    </div>

    <!-- <el-dialog title="上传头像" :show-close="false" :visible.sync="showUpdateFile" width="80%">
      <el-upload
        v-loading="loading"
        class="avatar-uploader"
        action="https://jsonplaceholder.typicode.com/posts/"
        :on-change="fileChange"
        :auto-upload="false"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
      >
        <img v-if="headSculpture" :src="headSculpture" class="avatar" style="width: 12rem; height: 12rem" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showUpdateFile = false">取 消</el-button>
        <el-button type="primary" @click="updateImageFile">确 定</el-button>
      </span>
    </el-dialog> -->

    <!-- 昵称修改 -->
    <van-popup v-model="nameShow" position="bottom" class="kedu" :style="{ height: '1.4rem' }">
      <div class="btn">
        <span class="first" @click="nameShow = false">取消</span>
        <span @click="onConfirmName">确认</span>
      </div>
      <input class="nameBox" v-model="userInfo.name" />
    </van-popup>
    <!-- 密码修改 -->
    <van-popup v-model="passwordShow" position="bottom" class="kedu" :style="{ height: '1.4rem' }">
      <div class="btn">
        <span class="first" @click="passwordShow = false">取消</span>
        <span @click="onConfirmPassword">确认</span>
      </div>
      <input class="nameBox" v-model="userInfo.password" />
    </van-popup>
    <!-- 身高修改 -->
    <van-popup v-model="heightShow" position="bottom" class="kedu" :style="{ height: '2.3rem' }">
      <div class="btn">
        <span class="first" @click="onCancelCm">取消</span>
        <span @click="onConfirmCm">确认</span>
      </div>
      <Ruler v-model="height" />
    </van-popup>
    <!-- 体重修改 -->
    <van-popup v-model="weightShow" position="bottom" class="kedu" :style="{ height: '2.3rem' }">
      <div class="btn">
        <span class="first" @click="onCancelKg">取消</span>
        <span @click="onConfirmKg">确认</span>
      </div>
      <RulerKg v-model="weight" />
    </van-popup>
    <!-- 城市修改 -->
    <van-popup v-model="areaShow" round position="bottom">
      <van-area title="选择城市" :area-list="areaList" @cancel="areaShow = false" @confirm="areaSave" />
    </van-popup>
    <!-- 二维码 -->
    <van-dialog v-model="showQRCCOde" title="我的二维码" confirmButtonText="关闭">
      <qrcCode style="margin: auto" :qrcType="qrcType"></qrcCode>
    </van-dialog>
  </div>
</template>
<script>
  import qrcCode from '@/pages/personnelManagement/components/qrcCode.vue'
  import Ruler from '@/pages/mineView/component/ruler.vue'
  import RulerKg from '@/pages/mineView/component/rulerKg.vue'
  import { areaList } from '@vant/area-data'
  import options from './data'
  import { Toast } from 'vant'
  import camera from '../../../static/pic/camera.png'
  import * as qiniu from 'qiniu-js'
  import { update, getQniuToken, loginOff } from '../../../api/userServices'
  export default {
    name: 'EditDialog',
    props: ['registerType'],
    components: { Ruler, RulerKg, qrcCode },
    data() {
      return {
        weight: '65',
        height: '175',
        heightShow: false,
        weightShow: false,
        areaShow: false,
        phoneShow: false,
        passwordShow: false,
        areaList: areaList,
        nameShow: false,
        options: options,
        dialogVisible: false,
        loading: false,
        headSculpture: '',
        showUpdateFile: false,
        filePath: '',
        camera: camera,
        qrcType: null,
        fileList: [],
        userInfo: {},
        showQRCCOde: false,
        tableData: [
          {
            label: '昵称',
            value: '222',
            source: 'name',
          },
          {
            label: '性别',
            value: '222',
            source: 'sex',
          },
          {
            label: '手机号',
            value: '222',
            source: 'iphone',
          },
          {
            label: '城市',
            value: '222',
            source: 'city',
          },
        ],
      }
    },
    mounted() {
      this.setUserInfo()
    },
    methods: {
      // 扫一扫
      openCamera() {
        const message = {
          message: '扫描二维码',
          body: {},
        }
        const androidMessage = JSON.stringify(message)
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.openCamera(androidMessage)
        } else {
          window.webkit.messageHandlers.openCamera.postMessage(message)
        }
      },
      // 我的二维码
      showCode() {
        this.qrcType = 1
        this.showQRCCOde = true
      },
      // 昵称
      onConfirmName() {
        this.update()
        this.nameShow = false
      },
      // 密码
      onConfirmPassword() {
        this.update()
        this.passwordShow = false
      },
      // 身高
      formatTooltipCm(value) {
        return `${value} cm`
      },
      onCancelCm() {
        this.heightShow = false
      },
      onConfirmCm() {
        this.userInfo.height = this.height + ''
        this.heightShow = false
        this.update()
      },
      // 体重
      formatTooltipKg(value) {
        return `${value} kg`
      },
      onCancelKg() {
        this.weightShow = false
      },
      onConfirmKg() {
        this.userInfo.weight = this.weight + ''
        this.weightShow = false
        this.update()
      },
      // 注销账户
      logOff() {
        this.$dialog
          .confirm({
            title: '注销账户',
            message: '您确定要注销账户吗?\n如需找回，请联系客服！',
          })
          .then(() => {
            const appuser = localStorage.getItem('appuser')
            const params = {
              uid: JSON.parse(appuser).id,
            }
            loginOff(params).then((res) => {
              if (res.data.code == 200) {
                localStorage.removeItem('appuser')
                localStorage.removeItem('hospital')
                localStorage.setItem('loginStatus', false)
                localStorage.removeItem('token', false)
                this.$router.push('/login')
              }
            })
          })
          .catch(() => {
            // on cancel
          })
      },
      editAva(image) {
        this.$router.push({
          path: '/editAva',
          query: {
            image,
          },
        })
      },
      goSugar() {
        this.$router.push({
          path: '/BloodSugar',
          query: { index: 0 },
        })
        Toast('登录成功！')
      },
      // 保存城市选择
      areaSave(e) {
        this.userInfo.city = e[0].name + e[1].name + e[2].name
        this.areaShow = false
        this.update()
      },
      goBackMineView() {
        // this.$emit("goBackMineView");
        this.$router.push('MineView')
      },
      updateImageFile() {
        this.loading = true
        this.updateHeadSculpture()
        setTimeout(() => {
          this.showUpdateFile = false
          this.loading = false
        }, 2000)
      },
      openUpload() {
        this.showUpdateFile = true
      },
      fileChange(file) {
        this.fileList.push(file.raw)
        this.file = file.raw
        this.fileList = []
        this.loading = true
        const userId = JSON.parse(localStorage.getItem('appuser')).id
        const suffix = this.file.name.split('.')[1] // 后缀名
        const timestamp = Date.parse(new Date())
        const key = userId + timestamp + '.' + suffix // key为上传后文件名 必填
        const config = { useCdnDomain: true, region: qiniu.region.z0 }
        const putExtra = { fname: this.file, params: {}, mimeType: null }
        const observe = {
          next(res) {
            console.log(res)
          },
          error(err) {
            console.log(err)
          },
          /* 完成后的操作 */
          complete(res) {
            // 上传成功以后会返回key 和 hash  key就是文件名了！
            console.log(res)
            // let fileUrl =baseUrl + '/' + res.key
          },
        }
        // 开始上传  token 需要找后端获取(单独的方法)
        getQniuToken().then((res) => {
          const token = res.data.data
          const headers = qiniu.getHeadersForMkFile(token)
          // file 是获取到的文件对象
          // key 是文件名字，传null将使用hash值来当作文件名
          const observable = qiniu.upload(this.file, key, token, headers, putExtra, config)
          this.subscription = observable.subscribe(observe)
          setTimeout(() => {
            this.headSculpture = 'https://oss.cgm.go-sugar.cn/' + key
            this.loading = false
          }, 2000)
        })
      },
      editUserInfo(item) {
        this.dialogVisible = true
      },
      setUserInfo() {
        this.userInfo = JSON.parse(localStorage.getItem('appuser'))
        if (this.userInfo.height) {
          this.height = this.userInfo.height
        } else {
          this.userInfo.height = '175'
        }
        if (this.userInfo.weight) {
          this.weight = this.userInfo.weight
        } else {
          this.userInfo.weight = '65'
        }
        this.headSculpture =
          !this.userInfo.images || this.userInfo.images == '未设置'
            ? 'https://i.postimg.cc/2SrXwLXR/litangbao.png'
            : this.userInfo.images
        for (let index = 0; index < this.tableData.length; index++) {
          this.tableData[index].value = this.userInfo[this.tableData[index].source]
            ? this.userInfo[this.tableData[index].source]
            : '未设置'
        }
      },
      beforeAvatarUpload(file) {
        // const isJPG = file.type === 'image/jpeg'
        const isLt2M = file.size / 1024 / 1024 < 2

        // if (!isJPG) {
        //   this.$toast('上传头像图片只能是 JPG 格式!')
        // }
        if (!isLt2M) {
          this.$toast('上传头像图片大小不能超过 2MB!')
        }
        return isJPG && isLt2M
      },
      canceleEdit() {
        this.setUserInfo()
        this.dialogVisible = false
      },
      updateChange() {
        this.update()
        this.dialogVisible = false
      },
      // 头像修改完立即上传
      updateHeadSculpture() {
        const user = JSON.parse(localStorage.getItem('appuser'))
        user.images = this.headSculpture
        update(user).then((res) => {
          if (res.data.code === '2003') {
            const newData = {
              ...user,
              loopRemote: JSON.parse(localStorage.getItem('appuser')).loopRemote,
            }
            localStorage.setItem('appuser', JSON.stringify(newData))
            this.setUserInfo()
          } else {
            Toast(res.data.msg)
          }
        })
      },
      update() {
        const user = JSON.parse(localStorage.getItem('appuser'))
        user.id = user.id ? user.id : '未设置'
        user.name = this.userInfo.name ? this.userInfo.name : '未设置'
        user.password = this.userInfo.password ? this.userInfo.password : '未设置'
        user.createdate = user.createdate ? user.createdate : '未设置'
        user.images = this.headSculpture ? this.headSculpture : '未设置'
        user.city = this.userInfo.city ? this.userInfo.city : '未设置'
        user.iphone = this.userInfo.iphone ? this.userInfo.iphone : '未设置'
        user.cgm = user.cgm ? user.cgm : '未设置'
        user.weight = this.userInfo.weight
        user.height = this.userInfo.height
        update(user).then((res) => {
          if (res.data.code === '200') {
            const newData = {
              ...user,
              loopRemote: JSON.parse(localStorage.getItem('appuser')).loopRemote,
            }
            localStorage.setItem('appuser', JSON.stringify(newData))
            this.setUserInfo()
            Toast(res.data.msg)
          } else {
            Toast(res.data.msg)
          }
        })
      },
    },
  }
</script>
<style scoped lang="less">
  #EditDialog {
    width: 100%;
    min-height: 100vh;
    padding-bottom: 0.5rem;
    position: absolute;
    top: 0;
    padding-top: 0.62rem;
    background: #e7e9f3;
    color: #292929;
  }
  * {
    box-sizing: border-box;
    i {
      font-style: normal;
    }
  }
  .van-cell {
    margin-bottom: 0.02rem;
  }
  // .el-dialog {
  //   border-radius: 1rem !important;
  // }
  .el-input__inner {
    color: #88d0fc !important;
    border: none !important;
  }
  ::v-deep .kedu {
    // height: 1.7rem;
    .el-slider {
      margin-top: 0.6rem;
      .el-slider__runway {
        width: 96%;
        margin: 0 0 0 2%;
      }
    }
    .btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 0.15rem;
      height: 0.4rem;
      border-bottom: 1px solid #f7f8f9;
      font-size: 0.13rem;
      color: #3f76f0;
      .first {
        color: #b2b2b2;
      }
    }
  }
  .file {
    background-color: #fff;
    width: 3.55rem;
    margin-left: 0.1rem;
    padding-left: 0.2rem;
    border-radius: 0.06rem;
    font-size: 0.13rem;
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 0.54rem;
      border-bottom: 1px solid #e8e8e8;
      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.54rem;
      }
      &:last-of-type {
        border-bottom: 0;
      }
      .van-icon {
        margin: 0 0.18rem 0 0.09rem;
      }
    }
  }
  .nameBox {
    padding-left: 0.2rem;
    width: 3.35rem;
    height: 0.4rem;
    margin-top: 0.2rem;
    border: 1px solid #6288e2;
    border-radius: 0.06rem;
  }
  ::v-deep .van-ellipsis {
    line-height: 0.44rem;
  }
  .zhuxiao {
    background-color: #fff;
    width: 3.55rem;
    margin-left: 0.1rem;
    padding: 0 0.18rem 0 0.2rem;
    border-radius: 0.06rem;
    font-size: 0.13rem;
    height: 0.54rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .info {
    margin: 0.13rem 0 0.08rem 0.29rem;
    font-size: 0.12rem;
    text-align: left;
  }
  ::v-deep .van-dialog__header {
    padding: 12px 0;
  }
  ::v-deep .van-nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.62rem;

    .van-nav-bar__content {
      height: 0.62rem;

      .van-nav-bar__left {
        .van-icon {
          font-size: 0.2rem;
          color: #292929;
        }
      }

      .van-nav-bar__title {
        font-size: 0.16rem;
        height: 0.62rem;
        line-height: 0.62rem;
        color: #009944;
      }
    }
  }
</style>
