<template>
  <div class="container">
    <div class="title">
      <img src="@/static/pic/8Icon.png" alt="" />
      <span>8点血糖值</span>
    </div>
    <div class="legend">
      <span class="extreme-high">极高</span>
      <span class="high">偏高</span>
      <span class="normal">达标</span>
      <span class="low">偏低</span>
      <span class="extreme-low">极低</span>
    </div>
    <table>
      <thead>
        <tr>
          <th rowspan="2">日期</th>
          <th rowspan="2">凌晨</th>
          <th colspan="2">早餐</th>
          <th colspan="2">午餐</th>
          <th colspan="2">晚餐</th>
          <th rowspan="2">睡前</th>
        </tr>
        <tr>
          <th>餐前</th>
          <th>餐后</th>
          <th>餐前</th>
          <th>餐后</th>
          <th>餐前</th>
          <th>餐后</th>
        </tr>
      </thead>
      <tbody id="table-body"></tbody>
    </table>
  </div>
</template>

<script>
  import { getEvent } from '@/api/userServices'
  export default {
    data() {
      return {
        sugarList: [],
      }
    },
    mounted() {
      this.getAllEvent()
    },
    methods: {
      getAllEvent() {
        // 获取糖仁云事件
        const params = {
          id: JSON.parse(localStorage.getItem('appuser')).id,
          start: this.$moment().subtract('6', 'days').format('YYYY-MM-DD 00:00:00'),
          end: this.$moment().format('YYYY-MM-DD 23:59:59'),
        }
        getEvent(params).then(({ data }) => {
          const list = data.data.filter((item) => item.type === 'zx')
          // 将事件数组转化成我们想要的样子
          this.sugarList = this.transformLast7Days(list)
          this.getChart()
        })
      },
      getLast7Days() {
        const result = []
        const today = new Date()
        for (let i = 0; i < 7; i++) {
          const temp = new Date(today)
          // “倒序”，今天是 i=0，昨天是 i=1，一直到 i=6
          temp.setDate(temp.getDate() - i)

          // 格式化为 YYYY-MM-DD
          const year = temp.getFullYear()
          const month = temp.getMonth() + 1
          const day = temp.getDate()
          // 补 0
          // const mm = month < 10 ? '0' + month : month
          // const dd = day < 10 ? '0' + day : day
          const fullDate = `${year}-${month}-${day}`

          result.push(fullDate)
        }
        // 如果想要正序，把数组反转一下即可
        // result.reverse();
        return result
      },
      // 将时间段转化成英文
      mapEventValueToKey(eventValue) {
        switch (eventValue) {
          case '晚餐后':
            return 'dinnerAfter'
          case '晚餐前':
            return 'dinnerBefore'
          case '午餐后':
            return 'lunchAfter'
          case '午餐前':
            return 'lunchBefore'
          case '早餐后':
            return 'breakfastAfter'
          case '早餐前':
            return 'breakfastBefore'
          case '凌晨':
            return 'night'
          case '睡前':
            return 'sleep'
          default:
            return null // 其他情况
        }
      },

      groupDataByDate(source) {
        const grouped = {}

        source.forEach((item) => {
          const [datePart] = item.createTime.split(' ') // "2025-01-06"
          const [year, month, day] = datePart.split('-').map(Number)
          const key = `${year}-${month}-${day}` // 直接用 'YYYY-MM-DD' 作为分组键

          if (!grouped[key]) {
            grouped[key] = {}
          }

          // 找到对应的时段字段
          const fieldKey = this.mapEventValueToKey(item.eventValue)
          if (fieldKey) {
            grouped[key][fieldKey] = item.way // 塞入测量值
          }
        })

        return grouped
      },

      transformLast7Days(source) {
        // 先拿到 7 天的 YYYY-MM-DD 列表
        const last7Dates = this.getLast7Days() // 倒序

        // 将源数据分组
        const grouped = this.groupDataByDate(source)

        // 最终结果数组
        const result = []

        last7Dates.forEach((fullDate) => {
          // 解析 YYYY-MM-DD => M/D (补零)
          const [year, month, day] = fullDate.split('-').map(Number)
          // const mm = month < 10 ? '0' + month : month
          // const dd = day < 10 ? '0' + day : day
          const displayDate = `${month}/${day}` // "01/06"

          // 初始化一条记录
          const record = {
            date: displayDate,
            night: '--',
            breakfastBefore: '--',
            breakfastAfter: '--',
            lunchBefore: '--',
            lunchAfter: '--',
            dinnerBefore: '--',
            dinnerAfter: '--',
            sleep: '--',
          }

          // 如果 grouped 里有这一天，就填充对应字段
          if (grouped[fullDate]) {
            const dayData = grouped[fullDate]
            Object.keys(dayData).forEach((fieldKey) => {
              record[fieldKey] = dayData[fieldKey]
            })
          }

          result.push(record)
        })
        return result
      },

      getChart() {
        // const data = [
        //   {
        //     date: '3/11',
        //     night: 2.1,
        //     breakfastBefore: '--',
        //     breakfastAfter: '--',
        //     lunchBefore: '--',
        //     lunchAfter: '--',
        //     dinnerBefore: '--',
        //     dinnerAfter: '--',
        //     sleep: 3.7,
        //   },
        //   {
        //     date: '3/10',
        //     night: 8.2,
        //     breakfastBefore: '--',
        //     breakfastAfter: '--',
        //     lunchBefore: '--',
        //     lunchAfter: '--',
        //     dinnerBefore: '--',
        //     dinnerAfter: '--',
        //     sleep: '--',
        //   },
        //   {
        //     date: '3/9',
        //     night: 12.3,
        //     breakfastBefore: '--',
        //     breakfastAfter: '--',
        //     lunchBefore: '--',
        //     lunchAfter: '--',
        //     dinnerBefore: '--',
        //     dinnerAfter: '--',
        //     sleep: 4.8,
        //   },
        //   {
        //     date: '3/8',
        //     night: 6.2,
        //     breakfastBefore: '--',
        //     breakfastAfter: '--',
        //     lunchBefore: '--',
        //     lunchAfter: '--',
        //     dinnerBefore: '--',
        //     dinnerAfter: '--',
        //     sleep: 6.4,
        //   },
        //   {
        //     date: '3/7',
        //     night: 7.9,
        //     breakfastBefore: '--',
        //     breakfastAfter: '--',
        //     lunchBefore: '--',
        //     lunchAfter: '--',
        //     dinnerBefore: '--',
        //     dinnerAfter: '--',
        //     sleep: 7.8,
        //   },
        //   {
        //     date: '3/6',
        //     night: 11.5,
        //     breakfastBefore: '--',
        //     breakfastAfter: '--',
        //     lunchBefore: '--',
        //     lunchAfter: '--',
        //     dinnerBefore: '--',
        //     dinnerAfter: '--',
        //     sleep: 5.6,
        //   },
        //   {
        //     date: '3/5',
        //     night: 22.2,
        //     breakfastBefore: '--',
        //     breakfastAfter: '--',
        //     lunchBefore: '--',
        //     lunchAfter: '--',
        //     dinnerBefore: '--',
        //     dinnerAfter: '--',
        //     sleep: 3.5,
        //   },
        // ]
        const data = this.sugarList

        function getClass(value) {
          if (value === '--') return ''
          if (value < 3) return 'extreme-low'
          if (value <= 3.9) return 'low'
          if (value > 3.9 && value <= 10) return 'normal'
          if (value > 10 && value <= 16) return 'high'
          if (value > 16) return 'extreme-high'
        }

        const tableBody = document.getElementById('table-body')

        data.forEach((row) => {
          const tr = document.createElement('tr')
          tr.innerHTML = `
              <td>${row.date}</td>
              <td class="${getClass(row.night)}">${row.night}</td>
              <td class="${getClass(row.breakfastBefore)}">${row.breakfastBefore}</td>
              <td class="${getClass(row.breakfastAfter)}">${row.breakfastAfter}</td>
              <td class="${getClass(row.lunchBefore)}">${row.lunchBefore}</td>
              <td class="${getClass(row.lunchAfter)}">${row.lunchAfter}</td>
              <td class="${getClass(row.dinnerBefore)}">${row.dinnerBefore}</td>
              <td class="${getClass(row.dinnerAfter)}">${row.dinnerAfter}</td>
              <td class="${getClass(row.sleep)}">${row.sleep}</td>
            `
          tableBody.appendChild(tr)
        })
      },
    },
  }
</script>

<style lang="less">
  .container {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 0.05rem 0.07rem 0.11rem;
    background: white;
    width: 3.55rem;
    border-radius: 0.06rem;
    font-size: 0.11rem;
    color: #848894;
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 0.13rem;
    height: 0.4rem;
    img {
      margin-right: 0.05rem;
      width: 0.18rem;
      height: 0.18rem;
    }
    span {
      height: 0.4rem;
      line-height: 0.4rem;
    }
  }

  .legend {
    display: flex;
    justify-content: flex-start;
    gap: 0.22rem;
    font-size: 0.11rem;
    margin-bottom: 0.15rem;
  }

  .legend span {
    display: flex;
    align-items: center;
    gap: 0.01rem;
  }

  .legend span::before {
    content: '';
    display: inline-block;
    width: 0.07rem;
    height: 0.07rem;
    border-radius: 50%;
    margin-right: 0.03rem;
  }

  .legend .extreme-high::before {
    background-color: #ff8500;
  }

  .legend .high::before {
    background-color: #e6a250;
  }

  .legend .normal::before {
    background-color: #5b8ff9;
  }

  .legend .low::before {
    background-color: red;
  }

  .legend .extreme-low::before {
    background-color: darkred;
  }

  .table-wrapper {
    overflow-x: auto; /* 横向滚动支持 */
  }

  table {
    width: 100%;
    max-width: 100%;
    border-collapse: separate; /* 单元格分隔 */
    border-spacing: 0.02rem; /* 单元格间距 */
    text-align: center;
  }
  thead {
    margin: 0.07rem;
  }

  th,
  td {
    background-color: white;
    // border: 1px solid #eaeaea;
    border-radius: 0.03rem; /* 单元格圆角 */
    padding: 0.06rem;
    background-color: #f6f7f9;
  }

  th {
    background-color: #f6f7f9;
    font-weight: bold;
  }

  td.normal {
    background-color: #beddff;
    color: #5b8ff9;
  }

  td.high {
    background-color: #fef0c3;
    color: #e6a250;
  }

  td.extreme-high {
    background-color: #fae6a9;
    color: #ff8500;
  }

  td.low {
    background-color: #ffcfd3;
    color: red;
  }

  td.extreme-low {
    background-color: #e2afb4;
    color: darkred;
  }
</style>
