<template>
  <div id="zxView">
    <div class="topHeader">
      <i class="el-icon-arrow-left" @click="goBack"></i>
      <div class="icon flex">
        <img src="@/static/pic/zxIcon.png" alt="" style="width: 0.29rem; height: 0.29rem; margin-right: 0.1rem" />
        <span style="color: #fff">添加指尖血</span>
      </div>
    </div>
    <div class="body">
      <van-cell title="时间" is-link :value="selectTime" @click="openSelectTime" class="must" />
      <van-field
        label="指血值(mmol/L)"
        type="number"
        v-model="zxNum"
        placeholder="请输入"
        input-align="right"
        class="must"
      />
      <div class="h4">
        <span>*</span>
        场景
      </div>
      <div class="typeList">
        <div
          v-for="(item, index) in typeList"
          :key="index"
          :class="{ active: currentIndex == index }"
          class="flex"
          @click="getType(item.name, index)"
        >
          <img :src="item.img" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>
      <!-- <el-row style="padding-left: 15px;padding-top: 5px;font-size: xx-small;font-weight: lighter;">一般指任意时间获得的血糖</el-row> -->
    </div>

    <!-- <div>
      <el-row>
        <el-col v-for="(item, index) in zxscene1" :key="index" :span="8" style="margin-bottom: 10px">
          <el-button round @click="selectZx1(item)">{{ item.id }}</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col v-for="(item, index) in zxscene2" :key="index" :span="8" style="margin-bottom: 10px">
          <el-button round @click="selectZx2(item)">{{ item.id }}</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col v-for="(item, index) in zxscene3" :key="index" :span="8">
          <el-button round @click="selectZx3(item)">{{ item.id }}</el-button>
        </el-col>
      </el-row>
    </div> -->
    <el-col class="save">
      <el-button @click="addzx" class="bottomBtn">
        <img src="@/static/pic/addBtn.png" alt="" style="width: 0.19rem; height: 0.19rem; margin-right: 0.09rem" />
        添 加
      </el-button>
    </el-col>
    <van-popup v-model="showTimeSelect" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择完整时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="saveTime"
        :formatter="formatter"
      />
    </van-popup>
  </div>
</template>
<script>
  import { addEvent } from '@/api/userServices'
  export default {
    name: 'zxView',
    components: {},
    mixins: [],
    props: {},
    data() {
      return {
        zxNum: '',
        zxscene1: [{ id: '夜间' }, { id: '空腹' }, { id: '早餐后' }],
        zxscene2: [{ id: '午餐前' }, { id: '午餐后' }, { id: '晚餐前' }],
        zxscene3: [{ id: '晚餐后' }, { id: '睡前' }, { id: '随机' }],
        selectedScene: '',
        showTimeSelect: false,
        selectTime: this.getFormatDate(new Date()),
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(2025, 10, 1),
        currentDate: new Date(),
        typeList: [
          { name: '早餐前', img: require('@/static/pic/Azaocan.png') },
          { name: '早餐后', img: require('@/static/pic/Azaocan.png') },
          { name: '午餐前', img: require('@/static/pic/Awancan.png') },
          { name: '午餐后', img: require('@/static/pic/Awancan.png') },
          { name: '晚餐前', img: require('@/static/pic/Awancan.png') },
          { name: '晚餐后', img: require('@/static/pic/Awancan.png') },
          { name: '睡前', img: require('@/static/pic/shuiqian.png') },
          { name: '凌晨', img: require('@/static/pic/lingchen.png') },
        ],
        currentIndex: 9,
      }
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {
      // selectZx1(item) {
      //   this.selectedScene = item.id
      //   console.log(item.id)
      // },
      // selectZx2(item) {
      //   this.selectedScene = item.id
      //   console.log(item.id)
      // },
      // selectZx3(item) {
      //   this.selectedScene = item.id
      //   console.log(item.id)
      // },
      getType(name, i) {
        this.currentIndex = i
        this.selectedScene = name
      },
      goBack() {
        this.$router.go(-1)
      },
      setBloodSugerTojs() {
        console.log('点击')
      },
      openSelectTime() {
        this.showTimeSelect = true
      },
      saveTime(value) {
        this.selectTime = this.getFormatDate(value)
        this.showTimeSelect = false
      },
      formatter(type, val) {
        if (type === 'year') {
          return val + '年'
        }
        if (type === 'month') {
          return val + '月'
        }
        if (type === 'day') {
          return val + '日'
        }
        if (type === 'hour') {
          return val + '时'
        }
        if (type === 'minute') {
          return val + '分'
        }
        return val
      },
      getFormatDate(time) {
        const date = new Date(time)
        const year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let hour = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        month = month < 10 ? '0' + month : month
        day = day < 10 ? '0' + day : day
        hour = hour < 10 ? '0' + hour : hour
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds
        const currentDate = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds
        return currentDate
      },
      addzx() {
        const params = {
          type: 'zx',
          createTime: this.selectTime,
          scene: '--',
          way: this.zxNum,
          slopeOrdinal:
            localStorage.getItem('slopeOrdinal') == 'undefined' || !localStorage.getItem('slopeOrdinal')
              ? 3
              : localStorage.getItem('slopeOrdinal'),
          value: localStorage.getItem('bloodSuger') ? localStorage.getItem('bloodSuger') : '5',
          eventValue: this.selectedScene,
        }
        // for (let index = 0; index < this.drugUsed.length; index++) {
        //     const element = this.drugUsed[index]
        //     params.way = element.name
        //     params.eventValue = element.value
        addEvent(params).then((res) => {
          if (res.data.code === '200') {
            this.$toast('添加成功!')
            this.$router.go(-1)
          } else {
            this.$toast('添加失败，请输入完整信息！')
          }
        })
        // }
      },
    },
  }
</script>
<style scoped lang="less">
  #zxView {
    box-sizing: border-box;
    padding-bottom: 0.9rem;
    width: 100vw;
    height: 100vh;
    background-color: #e7e9f3;
    overflow: auto;
  }

  .topHeader {
    position: relative;
    background-color: #fe433e;
    height: 1.26rem;
  }
  .el-icon-arrow-left {
    position: absolute;
    top: 0.22rem;
    left: 0.18rem;
    font-size: 0.24rem;
    color: #fff;
    height: 0;
    width: 0;
  }
  .icon {
    position: absolute;
    left: 0.19rem;
    bottom: 0.29rem;
  }
  .body {
    position: relative;
    margin-top: -0.13rem;
    padding: 0 0.1rem;
    background-color: #e7e9f3;
    border-radius: 0.1rem 0.1rem 0 0;
    overflow: hidden;
  }
  .van-cell {
    display: flex;
    align-items: center;
    border-radius: 0.06rem;
    height: 0.51rem;
    padding: 0 0.16rem;
    font-size: 0.13rem;
    border-radius: 0.06rem;
    margin-top: 0.13rem;
    ::v-deep .van-cell__title {
      span {
        display: block;
        width: 1rem;
        color: #848894;
      }
    }
    ::v-deep .van-cell__value {
      span {
        height: 0.16rem;
        line-height: 0.16rem;
      }
    }
  }
  .save {
    button {
      position: fixed;
      bottom: 0.14rem;
      left: 0.1rem;
      width: 3.55rem;
      height: 0.51rem;
      background-color: #009944;
      color: #fff;
      font-size: 0.16rem;
    }
  }
  .mainBody {
    border-radius: 0.06rem;
    overflow: hidden;
  }
  ::v-deep .van-ellipsis {
    height: 20px;
    line-height: 20px;
  }
  .van-icon {
    &::before {
      width: 0.1rem;
      font-size: 0.16rem;
    }
  }
  .h4 {
    box-sizing: border-box;
    padding-left: 0.2rem;
    margin-top: 0.13rem;
    width: 100%;
    height: 0.42rem;
    color: #848894;
    font-size: 0.13rem;
    line-height: 0.44rem;
    text-align: left;
    background-color: #fff;
    border-radius: 0.06rem 0.06rem 0 0;
    span {
      color: red;
    }
  }
  .typeList {
    padding-bottom: 0.04rem;
    width: 100%;
    height: 3.04rem;
    background-color: #fff;
    border-radius: 0 0 0.06rem 0.06rem;
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 1.54rem;
      height: 0.63rem;
      background-color: #f6f7fb;
      border-radius: 0.06rem;
      border: 1px solid #f6f7fb;
      &:nth-of-type(2n + 1) {
        margin: 0 0.1rem 0 0.19rem;
      }
      img {
        margin: 0 0.19rem 0 0.17rem;
        width: 0.32rem;
        height: 0.32rem;
      }
      span {
        font-size: 0.13rem;
        color: #292929;
      }
    }
  }
  .active {
    background-color: #f3faf7 !important;
    border: 1px solid #009944 !important;
  }
  .must {
    &::before {
      content: '*';
      color: red;
      margin-right: 0.03rem;
    }
  }
</style>
