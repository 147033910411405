import { render, staticRenderFns } from "./panelChart.vue?vue&type=template&id=27d02147&scoped=true&"
import script from "./panelChart.vue?vue&type=script&lang=js&"
export * from "./panelChart.vue?vue&type=script&lang=js&"
import style0 from "./panelChart.vue?vue&type=style&index=0&id=27d02147&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27d02147",
  null
  
)

export default component.exports