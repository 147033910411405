<template>
  <div class="panel" @click="openCGMDia()">
    <div class="left">
      <div class="left-top">
        <span>{{ cgmTypeName }}</span>
        <i v-show="isConnected">已连接</i>
        <i v-show="!isConnected" class="noCon">未连接</i>
      </div>
      <div class="left-bottom">
        <span style="margin-right: 14px">
          传感器剩余:
          <i>{{ leaveTime ? (leaveTime < 0 ? '已结束' : leaveTime + '天') : '--' }}</i>
        </span>
        <!-- <span>
          电池电量:
          <i>80%</i>
        </span> -->
      </div>
    </div>
    <div class="right">
      <div class="right-l">
        <span style="margin: 0.12rem 0">今日达标</span>
        <i>TIR:3.9~10.0</i>
      </div>
      <div class="right-r">
        <PanelChart :percentage="percentage" style="margin-left: 0.1rem" />
      </div>
    </div>
  </div>
</template>

<script>
  import PanelChart from '@/pages/bloodSugar/component/panelChart.vue'
  export default {
    props: ['cgmTypeName', 'leaveTime', 'percentage'],
    components: { PanelChart },
    data() {
      return {
        isConnected: true,
      }
    },
    methods: {
      openCGMDia() {
        this.$emit('openCGMDia')
      },
    },
  }
</script>

<style scoped lang="less">
  .panel {
    display: flex;
    background-color: #fff;
    color: #292929;
    padding: 0.2rem 0 0.3rem 0.21rem;
    .left {
      width: 60%;
      display: flex;
      flex-direction: column;
      .left-top {
        display: flex;
        align-items: center;
        font-size: 0.14rem;
        margin: 0.08rem 0;
        i {
          display: block;
          margin-left: 0.09rem;
          font-size: 0.1rem;
          width: 0.51rem;
          height: 0.19rem;
          text-align: center;
          line-height: 0.19rem;
          border-radius: 0.1rem;
          background-color: #009944;
          color: #fff;
        }
        .noCon {
          background-color: #666666;
          color: #fff;
        }
      }
      .left-bottom {
        text-align: left;
        span {
          font-size: 0.1rem;
          color: #848894;
          i {
            color: #292929;
          }
        }
      }
    }
    .right {
      width: 40%;
      display: flex;
      text-align: left;
      .right-l {
        display: flex;
        flex-direction: column;
        span {
          font-size: 0.14rem;
        }
        i {
          font-size: 0.1rem;
          color: #848894;
        }
      }
    }
  }
</style>
