<template>
  <div :class="title == 'bar1' ? 'barChart' : 'barChartT'" v-show="rateChart">
    <div :class="title == 'bar1' ? 'bar' : 'barT'">
      <div :class="`bloodRate1${title}`" style="background: #ffa300"></div>
      <div :class="`bloodRate2${title}`" style="background: #ffd200"></div>
      <div :class="`bloodRate3${title}`" style="background: #26d467"></div>
      <div :class="`bloodRate4${title}`" style="background: #ff666c"></div>
      <div :class="`bloodRate5${title}`" style="background: #ff191d"></div>
    </div>
    <div :class="title == 'bar1' ? 'word' : 'wordT'">
      <div class="bloodRate1">{{ bloodRate[0] == 'NaN' ? '-' : bloodRate[0] }}% 非常高</div>
      <div class="bloodRate2">{{ bloodRate[1] == 'NaN' ? '-' : bloodRate[1] }}% 高</div>
      <div class="bloodRate3">{{ bloodRate[2] == 'NaN' ? '-' : bloodRate[2] }}% 正常</div>
      <div class="bloodRate4">{{ bloodRate[3] == 'NaN' ? '-' : bloodRate[3] }}% 低</div>
      <div class="bloodRate5">{{ bloodRate[4] == 'NaN' ? '-' : bloodRate[4] }}% 非常低</div>
    </div>
  </div>
</template>

<script>
  import { getRate } from '@/pages/sugerData/getSugerData'
  export default {
    props: {
      SugerData: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
      },
    },
    data() {
      return {
        classList: [
          'bloodRate1' + this.title,
          'bloodRate2' + this.title,
          'bloodRate3' + this.title,
          'bloodRate4' + this.title,
          'bloodRate5' + this.title,
        ],
        bloodRate: [12, 24, 24, 12, 28],
        rateChart: false,
      }
    },
    mounted() {
      this.getBarValue()
    },
    methods: {
      getBarValue() {
        // 平均血糖柱状图数据
        this.bloodRate[0] = getRate(this.SugerData, 13.9, 100)
        this.bloodRate[1] = getRate(this.SugerData, 10, 13.9)
        this.bloodRate[2] = getRate(this.SugerData, 3.8, 10)
        this.bloodRate[3] = getRate(this.SugerData, 3, 3.8)
        this.bloodRate[4] = getRate(this.SugerData, 0, 3)
        this.bloodRate = [...this.bloodRate]
        this.setBloodRate()
      },
      setBloodRate() {
        for (let index = 0; index < this.classList.length; index++) {
          const element = this.classList[index]
          document.getElementsByClassName(element)[0].style.height =
            this.title == 'bar1' ? this.bloodRate[index] / 80 + 'rem' : this.bloodRate[index] / 180 + 'rem'
          // document.getElementsByClassName(element)[1].style.height = this.bloodRate[index] / 90 + 'rem'
          // document.getElementsByClassName(element)[1].style['line-height'] = this.bloodRate[index] / 90 + 'rem'
        }
        this.rateChart = true
      },
    },
    watch: {
      SugerData: {
        handler(newValue) {
          this.getBarValue()
        },
        deep: true, // 深度监听对象内部属性变化
      },
    },
  }
</script>

<style scoped>
  .barChart {
    height: 1.36rem;
  }
  .barChartT {
    height: 0.65rem;
  }
  .bar {
    float: left;
    margin-left: 0.24rem;
    width: 0.4rem;
  }
  .barT {
    float: left;
    margin-left: 0.24rem;
    width: 0.24rem;
  }
  .word {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 0.7rem;
    height: 100%;
    color: #333;
    font-size: 0.1rem;
  }
  .wordT {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
    color: #333;
    font-size: 0.06rem;
    margin-left: 0.52rem;
  }
  [class^='bloodRate'] {
    min-height: 3px;
    margin-top: 1px;
    border-radius: 2px;
  }
</style>
