<template>
  <div ref="CGMView" id="CGMView">
    <h2>添加血糖仪</h2>
    <div v-for="cgm in addCGMList" :key="cgm.id" @click="selectCGM(cgm)" class="cgmClass">
      <el-row>
        <el-col :span="19">
          <div
            style="
              padding-left: 0.12rem;
              font-weight: bold;
              text-align: left;
              height: 0.36rem;
              line-height: 0.36rem;
              font-size: 0.18rem;
              color: #fff;
            "
          >
            {{ cgm.name }}
          </div>
          <div
            style="
              padding-left: 0.12rem;
              font-weight: bold;
              text-align: left;
              height: 0.24rem;
              line-height: 0.24rem;
              font-size: 0.11rem;
              color: #fefefe;
            "
          >
            {{ cgm.des }}
          </div>
        </el-col>
        <el-col :span="5">
          <img :src="cgm.icon" style="width: 0.48rem; height: 0.48rem; margin-top: 0.06rem" />
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import yp from '../../../static/cgm/yp.png'
  import sn from '../../../static/cgm/snBig.png'
  import ww from '../../../static/cgm/wwBig.png'
  import gj from '../../../static/cgm/gj.png'
  // import { Toast } from 'vant'
  export default {
    name: 'CGMView',
    data() {
      return {
        addCGMList: [
          {
            id: 'ypsg',
            name: '雅培瞬感NFC',
            des: '支持家用版/医用版传感器，NFC扫描读取',
            icon: yp,
          },
          {
            id: 'wwfsq',
            name: '旺旺发射器',
            des: '支持Bubble/Bubble Mini蓝牙连接设备',
            icon: ww,
          },
          {
            id: 'gjdg',
            name: '硅基动感',
            des: '硅基1代传感器蓝牙实时读取',
            icon: gj,
          },
          {
            id: 'snak',
            name: '三诺爱看',
            des: '远程监护三诺爱看',
            icon: sn,
          },
        ],
        bindingCGM: {
          cgmName: '', // 名称
          cgmAddr: '', // 地址
          connectionStatus: '', // 连接状态
          connectionDate: '', // 连接时间
          cgmType: '', // 传感器类型
          driveNO: '', // 传感器序列号
          driveStatus: '', // 传感器状态
          powerPer: '', // 电池电
          firmware: '', // 固件
          hardware: '', // 硬件
        },
        isIos: localStorage.getItem('phoneType'),
      }
    },
    mounted() {
      window.openBluetooth = this.openBluetooth
      window.openLibreNFC = this.openLibreNFC
      window.openDexcomG6 = this.openDexcomG6
      window.disconnectDevice = this.disconnectDevice
      window.setDeviceInformation = this.setDeviceInformation
      window.setAndroidDeviceInformation = this.setAndroidDeviceInformation
    },
    methods: {
      selectCGM(cgm) {
        this.$emit('clickCgm', false)
        if (cgm.id === 'gjdg') {
          this.$router.push('/BloodSugar/GJEmpower')
        } else if (cgm.id === 'snak') {
          this.$router.push('/BloodSugar/SNEmpower')
        } else if (cgm.id === 'wwfsq') {
          this.$router.push('/BloodSugar/wangEmitter')
        } else if (cgm.id === 'ypsg') {
          this.openLibreNFC()
        } else if (cgm.id === 'dkg5') {
          this.openDexcomG6()
        } else {
          this.openBluetooth(cgm.id)
        }
      },
      // 发射雅培
      openLibreNFC() {
        const message = {
          message: '发射雅培',
          body: {
            type: 'LibreNFC',
          },
        }
        const androidMessage = JSON.stringify(message)
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.openLibreNFC(androidMessage)
        } else {
          window.webkit.messageHandlers.openLibreNFC.postMessage(message)
        }
      },
      // 发射德康
      openDexcomG6() {
        const message = {
          message: '发射德康',
          body: {
            type: 'Dexcom',
          },
        }
        const androidMessage = JSON.stringify(message)
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.openDexcomG6(androidMessage)
        } else {
          window.webkit.messageHandlers.openDexcomG6.postMessage(message)
        }
      },
      // 打来蓝牙
      openBluetooth(id) {
        this.colseCGMView()
        const message = {
          message: '打开蓝牙',
          body: {
            type: id,
            account: '',
            password: '',
          },
        }
        const androidMessage = JSON.stringify(message)
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.openBluetooth(androidMessage)
        } else {
          window.webkit.messageHandlers.openBluetooth.postMessage(message)
        }
      },
      // 断开蓝牙
      disconnectDevice() {
        const message = {
          message: '断开蓝牙',
          body: {},
        }
        const androidMessage = JSON.stringify(message)
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.disconnectDevice(androidMessage)
        } else {
          window.webkit.messageHandlers.disconnectDevice.postMessage(message)
        }
      },
      colseCGMView() {
        this.$emit('colseCGMView')
      },
      setDeviceInformation(device) {
        this.bindingCGM = {
          cgmName: device.cgmName, // 名称
          cgmAddr: device.cgmAddr, // 地址
          connectionStatus: device.connectionStatus, // 连接状态
          connectionDate: device.connectionDate, // 连接时间
          cgmType: device.cgmType, // 传感器类型
          driveNO: device.driveNO, // 传感器序列号
          driveStatus: device.driveStatus, // 传感器状态
          powerPer: device.powerPer, // 电池电
          firmware: device.firmware, // 固件
          hardware: device.hardware, // 硬件
        }
        this.$emit('selectThecGM', this.bindingCGM)
      },
      setAndroidDeviceInformation(info) {
        const device = JSON.parse(JSON.stringify(info))
        this.bindingCGM = {
          cgmName: device.cgmName, // 名称
          cgmAddr: device.cgmAddr, // 地址
          connectionStatus: device.connectionStatus, // 连接状态
          connectionDate: device.connectionDate, // 连接时间
          cgmType: device.cgmType, // 传感器类型
          driveNO: device.driveNO, // 传感器序列号
          driveStatus: device.driveStatus, // 传感器状态
          powerPer: device.powerPer, // 电池电
          firmware: device.firmware, // 固件
          hardware: device.hardware, // 硬件
        }
        this.$emit('selectThecGM', this.bindingCGM)

        return '确定'
      },
    },
  }
</script>
<style scoped lang="less">
  #CGMView {
    width: 100%;
    /* height: 26rem; */
  }
  h2 {
    font-size: 0.2rem;
    padding: 0.15rem 0 0.1rem 0;
    margin: 0;
  }
  .cgmClass {
    width: 3.55rem;
    height: 0.6rem;
    margin-left: 0.1rem;
    margin-top: 0.1rem;
    background: #009944;
    border-radius: 0.12rem;
    &:nth-of-type(3) {
      img {
        width: 0.34rem !important;
      }
    }
  }
  .el-row {
    height: 0.6rem;
  }
</style>
