// 导出页面为PDF格式

import html2Canvas from 'html2canvas'

import JsPDF from 'jspdf'

import FileSaver from 'file-saver'

export default {
  mounted() {
    // 分享报告
    window.shareFiles = this.shareFiles
  },
  install(Vue, options) {
    Vue.prototype.getPdf = function (id, title) {
      const loading = this.$loading({
        lock: true,
        text: '报告生成中，请耐心等待！',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      setTimeout(() => {
        loading.close()
      }, 3000)

      const scale = 2

      html2Canvas(document.querySelector(id), {
        allowTaint: true, // 开启跨域
        useCORS: true,
        scale, // 提升画面质量，但是会增加文件大小
      }).then(function (canvas) {
        const contentWidth = canvas.width / scale

        const contentHeight = canvas.height / scale

        let pageHeight = (contentWidth / 592.28) * 841.89 // 每页高度：a4纸的尺寸
        let leftHeight = contentHeight
        let position = 0
        let imgWidth = 590.28 // 图片宽度
        let imgHeight = (592.28 / contentWidth) * contentHeight

        let PDF = new JsPDF('p', 'pt', 'a4')

        const pageData = canvas.toDataURL('image/jpeg', 1.0)

        // 按照a4规格分页操作
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= 841.89
            if (leftHeight > 0) {
              PDF.addPage() // 添加页
            }
          }
        }

        let dataurl = PDF.output('dataurlstring').slice(51)
        const message = {
          message: '报告分享',
          body: {
            url: dataurl,
            type: 'report',
          },
        }
        window.webkit.messageHandlers.shareFiles.postMessage(message)

        // let arr = dataurl.split(","); //去掉base64格式图片的头部
        // let bstr = atob(arr[1]); //atob()方法将数据解码
        // let leng = bstr.length;
        // let u8arr = new Uint8Array(leng);
        // while (leng--) {
        //   u8arr[leng] = bstr.charCodeAt(leng); //返回指定位置的字符的 Unicode 编码
        // }
        // let blob = new Blob([u8arr], { type: "report" });
        // let blobImg = {};
        // blobImg.url = URL.createObjectURL(blob); //创建URL,
        // blobImg.name = title + ".pdf";
        // console.log(blob, "blob");
        // console.log(blobImg, "blobImg");

        // let file = blobImg.url.slice(5);

        // console.log(file, "file");

        // FileSaver.saveAs(dataurl, title + ".pdf");
        // const aLink = document.createElement("a");
        // document.body.appendChild(aLink);
        // aLink.href = blobImg.url;
        // aLink.download = title + ".pdf";
        // aLink.click();
        // window.URL.revokeObjectURL(blobImg.url);
      })
    }
  },
}
