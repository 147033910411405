import { render, staticRenderFns } from "./indexNew.vue?vue&type=template&id=7a5a733a&scoped=true&"
import script from "./indexNew.vue?vue&type=script&lang=js&"
export * from "./indexNew.vue?vue&type=script&lang=js&"
import style0 from "./indexNew.vue?vue&type=style&index=0&id=7a5a733a&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a5a733a",
  null
  
)

export default component.exports