<template>
  <div style="height: 100vh">
    <van-nav-bar title="医生认证" left-arrow left-text="返回" @click-left="goBackMineView" />
    <div class="doctor-certification">
      <el-form ref="form" :rules="rules" :model="form" label-width="110px">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入姓名" disabled></el-input>
        </el-form-item>
        <el-form-item label="医院" prop="hospital">
          <el-select v-model="form.hospital" placeholder="请选择医院" disabled>
            <el-option
              v-for="item in hospitalList"
              :key="item.structId"
              :label="item.structName"
              :value="item.structId"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="医生执业证书" prop="imageUrl">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :on-error="handleAvatarError"
            :before-upload="beforeAvatarUpload"
            v-loading="loading"
          >
            <img v-if="form.imageUrl" :src="form.imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item> -->
      </el-form>
      <!-- <van-button type="primary" block @click="submit('form')">提交</van-button> -->
    </div>
  </div>
</template>

<script>
  import { upload } from '@/unitls/qiniutil.js'
  import { getAllPassHos, authentication, agreeByStatus } from '../../../api/relationship'
  import { Notify } from 'vant'

  export default {
    data() {
      return {
        form: {
          name: '',
          hospital: '',
          imageUrl: '',
        },
        hospitalList: [],
        loading: false,
        rules: {
          name: [{ required: true, message: '请填写姓名', trigger: 'blur' }],
          hospital: [{ required: true, message: '请选择医院', trigger: 'blur' }],
          imageUrl: [{ required: true, message: '请选择医生执业证书', trigger: 'change' }],
        },
      }
    },
    created() {
      this.form.name = JSON.parse(localStorage.getItem('appuser')).name
      this.form.hospital = JSON.parse(localStorage.getItem('hospital')).struct_id
      this.getHospitalList()
    },
    methods: {
      // 上传成功
      handleAvatarSuccess(res, file) {
        const filePath = file.raw
        // 处理文件上传逻辑，例如将文件转为base64等
        // ...
        upload(filePath, (url) => {
          this.form.imageUrl = url
          this.loading = false

          this.submit('form')
        })
      },
      handleAvatarError(res, file) {
        const filePath = file.raw
        // 处理文件上传逻辑，例如将文件转为base64等
        // ...
        upload(filePath, (url) => {
          this.form.imageUrl = url
          this.loading = false

          this.submit('form')
        })
      },
      beforeAvatarUpload(file) {
        this.loading = true
        // const isJPG = file.type === 'image/jpeg'
        const isLt5M = file.size / 1024 / 1024 < 5

        // if (!isJPG) {
        //   this.$toast('上传图片只能是 JPG 格式!')
        // }
        if (!isLt5M) {
          this.$toast('上传图片大小不能超过 5MB!')
        }
        return isLt5M
      },

      getHospitalList() {
        getAllPassHos({ status: 1 }).then((res) => {
          this.hospitalList = res.data.data
        })
      },
      goBackMineView() {
        this.$router.go(-1)
      },
      submit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // 提交表单
            const appuser = localStorage.getItem('appuser')

            const params = {
              doctorId: JSON.parse(appuser).id,
              structId: this.form.hospital,
              name: this.form.name,
              zizhi: this.form.imageUrl,
            }
            authentication(params).then((res) => {
              if (res.data.code == 200) {
                this.$router.go(-1)
                Notify({ type: 'success', message: res.data.msg })
              } else {
                Notify({ type: 'warning', message: res.data.msg })
              }
            })
          } else {
            // 表单校验失败
            return false
          }
        })
      },
    },
  }
</script>

<style scoped>
  .doctor-certification {
    padding: 20px;
  }
  .el-select {
    width: 100%;
  }
  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
