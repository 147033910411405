<template>
  <div id="tsView">
    <div class="topHeader">
      <i class="el-icon-arrow-left" @click="goBack"></i>
      <div class="icon flex">
        <img src="@/static/pic/tsIcon.png" alt="" style="width: 0.29rem; height: 0.29rem; margin-right: 0.1rem" />
        <span style="color: #fff">添加碳水化合物</span>
      </div>
    </div>
    <div class="body">
      <van-cell title="时间" is-link :value="selectTime" @click="openSelectTime" style="margin-top: 0" class="must" />
      <van-field label="食物名称" v-model="foodName" input-align="right" placeholder="例如米饭、牛肉、蔬菜等" />
      <div class="type">
        <i>类型</i>
        <div class="typeList flex-a">
          <div
            v-for="(item, index) in typeList"
            :key="index"
            :class="{ active: currentIndex == index }"
            class="flex-c"
            @click="getType(item.name, index)"
          >
            <img :src="item.img" alt="" />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <!-- <van-cell title="用餐类型" is-link :value="EatType" @click="openEatType" /> -->

      <el-col class="save">
        <el-button @click="addts" class="bottomBtn">
          <img src="@/static/pic/addBtn.png" alt="" style="width: 0.19rem; height: 0.19rem; margin-right: 0.09rem" />
          添 加
        </el-button>
      </el-col>
      <van-popup v-model="showTimeSelect" position="bottom" :style="{ height: '40%' }">
        <van-datetime-picker
          v-model="currentDate"
          type="datetime"
          title="选择完整时间"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="saveTime"
          :formatter="formatter"
        />
      </van-popup>
      <!-- <van-popup v-model="showEatType" round position="bottom" :style="{ height: '50%' }">
        <van-picker
          style="line-height: 2rem"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="closeEatType"
        />
      </van-popup> -->
    </div>
  </div>
</template>
<script>
  import { addEvent } from '@/api/userServices'
  export default {
    name: 'tsView',
    components: {},
    mixins: [],
    props: {},
    data() {
      return {
        currentIndex: 5,
        imageUrl: '',
        showEatType: false,
        columns: ['餐前', '餐时', '餐后'],
        EatType: '餐前',
        foodName: '',
        TimeRange: '请选择',
        eatType: '请选择',
        showTimeSelect: false,
        selectTime: this.getFormatDate(new Date()),
        endTime: this.getFormatDate(new Date()),
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(2025, 10, 1),
        currentDate: new Date(),
        typeList: [
          { name: '早餐', img: require('@/static/pic/zaocan.png') },
          { name: '午餐', img: require('@/static/pic/wucan.png') },
          { name: '晚餐', img: require('@/static/pic/wancan.png') },
          { name: '加餐', img: require('@/static/pic/jiacan.png') },
        ],
      }
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {
      getType(type, i) {
        this.currentIndex = i
        this.EatType = type
      },
      goBack() {
        this.$router.go(-1)
      },
      // openEatType() {
      //   this.showEatType = true
      // },
      // onConfirm(value, index) {
      //   this.EatType = value
      //   this.showEatType = false
      // },
      // closeEatType() {
      //   this.showEatType = false
      // },
      openSelectTime() {
        this.showTimeSelect = true
      },
      saveTime(value) {
        this.selectTime = this.getFormatDate(value)
        this.showTimeSelect = false
      },
      formatter(type, val) {
        if (type === 'year') {
          return val + '年'
        }
        if (type === 'month') {
          return val + '月'
        }
        if (type === 'day') {
          return val + '日'
        }
        if (type === 'hour') {
          return val + '时'
        }
        if (type === 'minute') {
          return val + '分'
        }
        return val
      },
      getFormatDate(time) {
        const date = new Date(time)
        const year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let hour = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        month = month < 10 ? '0' + month : month
        day = day < 10 ? '0' + day : day
        hour = hour < 10 ? '0' + hour : hour
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds
        const currentDate = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds
        return currentDate
      },
      addts() {
        const params = {
          type: 'ts',
          createTime: this.selectTime,
          scene: '--',
          way: this.EatType,
          slopeOrdinal:
            localStorage.getItem('slopeOrdinal') == 'undefined' || !localStorage.getItem('slopeOrdinal')
              ? 3
              : localStorage.getItem('slopeOrdinal'),
          value: localStorage.getItem('bloodSuger') ? localStorage.getItem('bloodSuger') : '5',
          eventValue: this.foodName,
        }
        addEvent(params).then((res) => {
          if (res.data.code === '200') {
            this.$toast('添加成功!')
            this.$router.go(-1)
          } else {
            this.$toast('添加失败，请输入完整信息！')
          }
        })
      },
    },
  }
</script>
<style scoped lang="less">
  #tsView {
    box-sizing: border-box;
    padding-bottom: 0.7rem;
    width: 100vw;
    height: 100vh;
    background-color: #e7e9f3;
    overflow: auto;
  }

  .topHeader {
    position: relative;
    background-color: #25ce69;
    height: 1.26rem;
  }
  .el-icon-arrow-left {
    position: absolute;
    top: 0.22rem;
    left: 0.18rem;
    font-size: 0.24rem;
    color: #fff;
    height: 0;
    width: 0;
  }
  .icon {
    position: absolute;
    left: 0.19rem;
    bottom: 0.29rem;
  }
  .body {
    position: relative;
    margin-top: -0.13rem;
    padding: 0.14rem 0.1rem;
    background-color: #e7e9f3;
    border-radius: 0.1rem 0.1rem 0 0;
    overflow: hidden;
  }
  .van-cell {
    display: flex;
    align-items: center;
    border-radius: 0.06rem;
    height: 0.51rem;
    padding: 0 0.16rem;
    font-size: 0.13rem;
    border-radius: 0.06rem;
    margin-top: 0.13rem;
    ::v-deep .van-cell__title {
      span {
        color: #848894;
      }
    }
    ::v-deep .van-cell__value {
      span {
        height: 0.16rem;
        line-height: 0.16rem;
      }
    }
  }
  .save {
    button {
      position: fixed;
      bottom: 0.14rem;
      left: 0.1rem;
      width: 3.55rem;
      height: 0.51rem;
      background-color: #009944;
      color: #fff;
      font-size: 0.16rem;
    }
  }
  .mainBody {
    border-radius: 0.06rem;
    overflow: hidden;
  }
  ::v-deep .van-ellipsis {
    height: 20px;
    line-height: 20px;
  }
  .van-icon {
    &::before {
      width: 0.1rem;
      font-size: 0.16rem;
    }
  }

  .type {
    position: relative;
    box-sizing: border-box;
    padding: 0 0.12rem;
    width: 100%;
    height: 1.28rem;
    border-radius: 0.06rem;
    background-color: #fff;
    margin-top: 0.13rem;
    overflow: hidden;
    .typeList {
      margin-top: 0.48rem;
      & > div {
        border: 1px solid #f6f7fb;
        background-color: #f6f7fb;
        width: 0.71rem;
        height: 0.64rem;
        border-radius: 0.1rem;
        img {
          margin: 0.11rem 0 0.09rem 0;
          width: 0.26rem;
          height: 0.26rem;
        }
        span {
          font-size: 0.1rem;
          color: #292929;
        }
      }
    }
    i {
      position: absolute;
      top: 0.2rem;
      font-size: 0.13rem;
      left: 0.16rem;
      color: #848894;
      &:nth-of-type(2) {
        left: 3.1rem;
        color: #009944;
      }
    }
  }
  .active {
    background-color: #f3faf7 !important;
    border: 1px solid #009944 !important;
  }
  .must {
    &::before {
      content: '*';
      color: red;
      margin-right: 0.03rem;
    }
  }
  ::v-deep .van-field__label::before {
    content: '*';
    color: red;
    margin-right: 4px;
  }
</style>
