<template>
  <div style="text-align: center">
    <!-- 调整图表宽度和高度为 100x100 -->
    <div ref="chart" id="chart"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    name: 'CircularProgressChart',
    props: {
      percentage: {
        required: true,
        default: 0,
      },
    },
    mounted() {
      this.initChart()
    },
    methods: {
      initChart() {
        const chart = echarts.init(this.$refs.chart)

        // 根据百分比动态设置颜色
        let color
        let leftColor
        if (this.percentage >= 75) {
          color = new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            { offset: 0, color: '#94e8b9' },
            { offset: 1, color: '#009944' },
          ])
          leftColor = '#d4f4e2'
        } else if (this.percentage >= 50) {
          color = new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            { offset: 0, color: '#fff2e1' },
            { offset: 1, color: '#ff9100' },
          ])
          leftColor = '#fff2e1'
        } else {
          color = new echarts.graphic.LinearGradient(0, 0, 1, 1, [
            { offset: 0, color: '#ff9797' },
            { offset: 1, color: '#df2525' },
          ])
          leftColor = '#fae8e8'
        }

        const option = {
          series: [
            {
              type: 'pie',
              radius: ['66%', '80%'], // 调整 radius 以适应 100x100 的图表大小
              startAngle: 90,
              endAngle: 450,
              data: [
                {
                  value: this.percentage,
                  name: 'Progress',
                  itemStyle: {
                    color: color,
                  },
                },
                {
                  value: 100 - this.percentage,
                  name: 'Remaining',
                  itemStyle: {
                    color: leftColor,
                  },
                },
              ],
              label: {
                show: true,
                position: 'center',
                formatter: `{a|${this.percentage}}{b|%}`,
                rich: {
                  a: {
                    fontSize: 12, // 主数字的字体大小
                    color: '#333',
                    lineHeight: 12, // 行高
                  },
                  b: {
                    fontSize: 12, // 主数字的字体大小
                    color: '#333',
                    lineHeight: 12, // 行高
                  },
                },
              },
              hoverAnimation: false,
            },
          ],
        }

        chart.setOption(option)
      },
    },
    watch: {
      percentage() {
        this.initChart()
      },
    },
  }
</script>

<style scoped>
  /* 可根据需求调整样式 */
  #chart {
    width: 0.58rem;
    height: 0.58rem;
    display: inline-block;
    position: relative;
  }
</style>
