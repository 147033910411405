<template>
  <div id="app">
    <homePages></homePages>
    <waitingB v-if="isGetPhone" class="waitingB" :userA="userA" :baiban_id="baiban_id" @getShow="getShow" />
  </div>
</template>
<script>
  import { getIsPhone } from '@/api/userServices'
  import homePages from './pages/homePages/index.vue'
  import waitingB from '@/pages/recordDetail/waitingB'
  export default {
    data() {
      return {
        timer: '',
        isGetPhone: false,
        userA: {},
        baiban_id: '',
      }
    },
    created() {
      this.fbiSF()
      this.getPhoneType()
    },
    mounted() {
      this.timer = setInterval(() => {
        this.getPhone()
      }, 5000)
    },
    components: { homePages, waitingB },
    methods: {
      getShow() {
        this.isGetPhone = false
      },
      getPhone() {
        const params = {
          uid: JSON.parse(localStorage.getItem('appuser')).id,
        }
        getIsPhone(params).then((res) => {
          if (!res.data.data) {
            this.isGetPhone = false
          } else {
            if (!res.data.data.baiban.statusb) {
              this.isGetPhone = true
              this.userA = res.data.data.userA
              this.baiban_id = res.data.data.baiban.baiban_id
            } else if (res.data.data.baiban.statusb == 1) {
              if (this.$route.path != '/baiban') {
                this.$router.replace({
                  path: 'baiban',
                  query: {
                    baibanId: res.data.data.baiban.baiban_id,
                  },
                })
              }
            }
          }
        })
      },
      getPhoneType() {
        var u = navigator.userAgent
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 //android终端或者uc浏览器
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端

        if (isAndroid) {
          //Android
          localStorage.setItem('phoneType', 'Android')
        } else if (isiOS) {
          //ios
          localStorage.setItem('phoneType', 'ios')
        } else {
          localStorage.setItem('phoneType', 'web')
        }
      },
      fbiSF() {
        // 禁用双指放大
        document.documentElement.addEventListener(
          'touchstart',
          function (event) {
            if (event.touches.length > 1) {
              event.preventDefault()
            }
          },
          { passive: false }
        )

        // 禁用双击放大
        let lastTouchEnd = 0
        document.documentElement.addEventListener(
          'touchend',
          function (event) {
            const now = Date.now()
            if (now - lastTouchEnd <= 300) {
              event.preventDefault()
            }
            lastTouchEnd = now
          },
          { passive: false }
        )
      },
    },
  }
</script>
<style lang="less">
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fff;
    text-align: center;
    color: #2c3e50;
    width: 100vw;
    font-size: 0.16rem;
    font-weight: 500;
  }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
      font-size: 16px;
    }
  }
  html {
    font-size: 12px;
    line-height: 12px;
  }
  .waitingB {
    position: absolute !important;
    top: 0;
  }
</style>
