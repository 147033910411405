<template>
  <div id="departmentView" class="flow-container" v-touch:swipe="swipeHandler">
    <div style="line-height: 2rem; height: 23rem; margin-top: -4rem">
      <van-picker :columns="options" @change="onChange" />
    </div>
    <van-cell-group style="margin-top: 1rem">
      <van-field v-model="createBody.structName" label="医院名" placeholder="请输入医院名" />
      <van-field v-model="createBody.department" label="科室/组织" placeholder="科室/组织" />
    </van-cell-group>
    <el-row style="margin-top: 3rem">
      <el-col :span="24">
        <el-button style="width: 90%; margin-left: 5%" @click="sureCreateStruct" type="primary">确认</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { createStruct } from '../../../api/institution'
  import { adressList } from '../../../unitls/adress'
  export default {
    name: 'departmentView',
    data() {
      return {
        createBody: {
          province: '北京市',
          city: '市辖区',
          region: '东城区',
          structName: '',
          department: '',
          json: '{"directorList":[], "doctorList":[], "nurseList": []}',
        },
        options: [],
      }
    },
    mounted() {
      this.options = adressList
    },
    methods: {
      onChange(picker, value, index) {
        this.createBody.province = value[0]
        this.createBody.city = value[1]
        this.createBody.region = value[2]
      },
      sureCreateStruct() {
        if (this.createBody.province && this.createBody.city && this.createBody.region) {
          createStruct(this.createBody).then((res) => {
            if (res.data.code === '200') {
              this.$toast('创建成功')
              this.$emit('closeDoalog')
            } else {
              this.$message(res.data.msg)
            }
          })
        } else {
          this.$message.warning('请填写完信息再确认')
        }
      },
      swipeHandler(direction) {
        if (direction === 'left') {
          console.log('left')
        } else if (direction === 'right') {
          this.$router.push('/departmentList')
        }
      },
    },
  }
</script>

<style>
  #departmentView {
    width: 94%;
    margin-left: 3%;
    height: 40vh;
  }

  .labelClass {
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    font-size: 1.6rem;
  }

  .titleClass {
    height: 6rem;
    line-height: 6rem;
    z-index: 9;
    font-size: 2rem;
    font-weight: bold;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
  }

  .textAlign_right {
    text-align: right;
  }
</style>
