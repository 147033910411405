<template>
  <div id="kfyView">
    <div class="topHeader">
      <i class="el-icon-arrow-left" @click="goBack"></i>
      <div class="icon flex">
        <img src="@/static/pic/kfyIcon.png" alt="" style="width: 0.29rem; height: 0.29rem; margin-right: 0.1rem" />
        <span style="color: #fff">添加口服药</span>
      </div>
    </div>
    <div class="body">
      <div class="mainBody">
        <van-cell title="时间" is-link :value="selectTime" @click="openSelectTime" />
        <!-- <van-cell title="用药时段" is-link :value="periodOfTime" @click="openPeriodOfTime" /> -->
      </div>
      <van-cell-group class="midBox">
        <div
          v-for="(item, i) in drugUsed"
          :key="'drug' + i"
          style="margin-top: 0.13rem; border-radius: 0.06rem; overflow: hidden"
        >
          <van-field v-model="item.name" input-align="right" label="药品名称" placeholder="请输入药品名称" />
          <van-field
            v-model="item.value"
            input-align="right"
            label="用药量(mg)"
            placeholder="请输入用药量(mg)"
            type="number"
          />
        </div>
      </van-cell-group>
      <el-row class="handleBtn">
        <el-button @click="addDrugUsed">
          <i class="el-icon-circle-plus-outline"></i>
          增加用药
        </el-button>
        <el-button @click="deleteDrugUsed" style="margin-left: 0.11rem">
          <i class="el-icon-remove-outline"></i>
          减少用药
        </el-button>
      </el-row>
      <el-row class="save">
        <el-button @click="addRecords" class="bottomBtn">
          <img src="@/static/pic/addBtn.png" alt="" style="width: 0.19rem; height: 0.19rem; margin-right: 0.09rem" />
          添加
        </el-button>
      </el-row>
      <van-popup v-model="showTimeSelect" position="bottom" :style="{ height: '40%' }">
        <van-datetime-picker
          style="line-height: 0.24rem"
          v-model="currentDate"
          type="datetime"
          title="选择完整时间"
          :min-date="minDate"
          :max-date="maxDate"
          @confirm="saveTime"
          :formatter="formatter"
          @cancel="closeTimeSelect"
        />
      </van-popup>
      <van-popup v-model="showPeriodOfTime" round position="bottom" :style="{ height: '50%' }">
        <van-picker
          style="line-height: 0.24rem"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="closePeriodOfTime"
        />
      </van-popup>
    </div>
  </div>
</template>
<script>
  import { addEvent } from '../../../api/userServices'
  export default {
    name: 'kfyView',
    components: {},
    mixins: [],
    props: {},
    data() {
      return {
        showTimeSelect: false,
        showPeriodOfTime: false,
        columns: ['餐前', '餐时', '餐后'],
        selectTime: this.getFormatDate(new Date()),
        periodOfTime: '餐前',
        drugUsed: [
          {
            name: '',
            value: '',
          },
        ],
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(2025, 10, 1),
        currentDate: new Date(),
      }
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      openSelectTime() {
        this.showTimeSelect = true
      },
      openPeriodOfTime() {
        this.showPeriodOfTime = true
      },
      closePeriodOfTime() {
        this.showPeriodOfTime = false
      },
      closeTimeSelect() {
        this.showTimeSelect = false
      },
      addDrugUsed() {
        this.drugUsed.push({
          name: '',
          value: '',
        })
      },
      deleteDrugUsed() {
        if (this.drugUsed.length <= 1) {
          this.$toast('至少保留一条嘛!')
        } else {
          this.drugUsed.pop()
        }
      },
      saveTime(value) {
        this.selectTime = this.getFormatDate(value)
        this.showTimeSelect = false
      },
      onConfirm(value, index) {
        this.periodOfTime = value
        this.showPeriodOfTime = false
      },
      formatter(type, val) {
        if (type === 'year') {
          return val + '年'
        }
        if (type === 'month') {
          return val + '月'
        }
        if (type === 'day') {
          return val + '日'
        }
        if (type === 'hour') {
          return val + '时'
        }
        if (type === 'minute') {
          return val + '分'
        }
        return val
      },
      getFormatDate(time) {
        const date = new Date(time)
        const year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let hour = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        month = month < 10 ? '0' + month : month
        day = day < 10 ? '0' + day : day
        hour = hour < 10 ? '0' + hour : hour
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds
        const currentDate = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds
        return currentDate
      },
      addRecords() {
        const params = {
          type: 'kfy',
          createTime: this.selectTime,
          scene: this.periodOfTime,
          way: '',
          slopeOrdinal:
            localStorage.getItem('slopeOrdinal') == 'undefined' || !localStorage.getItem('slopeOrdinal')
              ? 3
              : localStorage.getItem('slopeOrdinal'),

          value: localStorage.getItem('bloodSuger') ? localStorage.getItem('bloodSuger') : '5',
          eventValue: '',
        }
        for (let index = 0; index < this.drugUsed.length; index++) {
          const element = this.drugUsed[index]
          params.way = element.name
          params.eventValue = element.value
          addEvent(params).then((res) => {
            if (res.data.code === '200') {
              this.$toast('添加成功!')
              this.$router.go(-1)
            } else {
              this.$toast('添加失败，请输入完整信息！')
            }
          })
        }
      },
    },
  }
</script>
<style scoped lang="less">
  #kfyView {
    box-sizing: border-box;
    padding-bottom: 0.7rem;
    width: 100vw;
    height: 100vh;
    background-color: #e7e9f3;
    position: fixed;
    overflow: auto;
  }

  .topHeader {
    position: relative;
    background-color: #8b3efe;
    height: 1.26rem;
  }
  .el-icon-arrow-left {
    position: absolute;
    top: 0.22rem;
    left: 0.18rem;
    font-size: 0.24rem;
    color: #fff;
    height: 0;
    width: 0;
  }
  .icon {
    position: absolute;
    left: 0.19rem;
    bottom: 0.29rem;
  }
  .body {
    position: relative;
    margin-top: -0.13rem;
    padding: 0.14rem 0.1rem;
    background-color: #e7e9f3;
    border-radius: 0.1rem 0.1rem 0 0;
    overflow: hidden;
  }
  .midBox {
    background-color: #e7e9f3;
    border-radius: 0.06rem;
    margin: 0.13rem 0;
    .van-cell {
      border-radius: 0;
    }
    ::v-deep .van-cell__title {
      span {
        color: #848894;
      }
    }
  }
  .save {
    button {
      position: fixed;
      bottom: 0.14rem;
      left: 0.1rem;
      width: 3.55rem;
      height: 0.51rem;
      background-color: #009944;
      color: #fff;
      font-size: 0.16rem;
    }
  }

  .van-cell {
    display: flex;
    align-items: center;
    border-radius: 0.06rem;
    height: 0.51rem;
    padding: 0 0.16rem;
    font-size: 0.13rem;
    .van-cell__title {
      &::before {
        content: '*';
        color: red;
        margin-right: 0.03rem;
      }
      color: #848894;
    }
  }
  .handleBtn {
    button {
      width: 1.72rem;
      height: 0.4rem;
      font-size: 0.14rem;
      color: #848894;
      border-radius: 0.06rem;
      border: 0;
    }
  }
  .van-icon {
    &::before {
      width: 0.1rem;
      font-size: 0.16rem;
    }
  }
  ::v-deep .van-field__label::before {
    content: '*';
    color: red;
    margin-right: 4px;
  }
</style>
