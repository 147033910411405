<template>
  <div id="userTableView">
    <h3>用户管理</h3>
    <i class="el-icon-arrow-left" @click="goBack"></i>
    <div style="width: 90%; margin-left: 5%">
      <van-search v-model="name" show-action label="用户名" placeholder="请输入用户名" @search="onSearch">
        <template #action>
          <el-button size="mini" @click="onSearch">搜索</el-button>
        </template>
      </van-search>
    </div>
    <div style="width: 90%; margin-left: 5%; height: 1rem"></div>
    <div>
      <el-table size="mini" :data="tableData" border tooltip-effect="dark" style="width: 90%; margin-left: 5%">
        <el-table-column fixed="left" prop="name" label="用户名" width="110"></el-table-column>
        <el-table-column prop="role" label="角色"></el-table-column>
        <el-table-column prop="city" label="城市"></el-table-column>
        <el-table-column prop="structId" label="所属机构" width="300"></el-table-column>
        <el-table-column prop="cgm" label="血糖仪类型" width="120"></el-table-column>
        <el-table-column prop="targetLow" label="低血糖预警" width="120"></el-table-column>
        <el-table-column prop="targetHigh" label="高血糖预警" width="120"></el-table-column>
        <el-table-column prop="targetLow" label="紧急低血糖预警" width="120"></el-table-column>
        <el-table-column prop="targetHigh" label="紧急高血糖预警" width="120"></el-table-column>
        <el-table-column prop="checked" label="被认证"></el-table-column>
        <el-table-column prop="createdate" label="创建时间" width="160"></el-table-column>
        <el-table-column fixed="right" label="操作" width="50">
          <template slot-scope="scope">
            <el-button @click.native.prevent="editUser(tableData[scope.$index])" type="text" size="small">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <van-popup v-model="showEdit" position="bottom" :style="{ height: '40rem' }">
      <el-row style="margin-top: 2rem">
        <el-col :offset="4" :span="16">
          <van-cell-group>
            <van-field disabled v-model="userInfo.name" label="用户名" placeholder="请选择角色"></van-field>
          </van-cell-group>
        </el-col>
      </el-row>
      <el-row>
        <el-col :offset="4" :span="16">
          <van-cell-group @click="showRoleEdit">
            <van-field v-model="userInfo.roleLabel" label="角色" placeholder="点击编辑"></van-field>
          </van-cell-group>
        </el-col>
      </el-row>
      <el-row>
        <el-col :offset="4" :span="16">
          <van-cell-group @click="showStructEdit">
            <van-field v-model="userInfo.structName" label="所属机构" placeholder="点击编辑"></van-field>
          </van-cell-group>
        </el-col>
      </el-row>
      <el-row>
        <el-col :offset="4" :span="16">
          <van-cell-group>
            <van-field
              v-for="(item, i) in editList"
              :key="'edit' + item.label + i"
              v-model="item.value"
              :label="item.label"
            ></van-field>
          </van-cell-group>
        </el-col>
      </el-row>
      <van-popup v-model="showSlectRole" position="bottom" :style="{ height: '30rem' }">
        <van-picker
          style="line-height: 2rem"
          title="选择角色"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm1"
          @cancel="onCancel1"
        />
      </van-popup>
      <van-popup v-model="showStractSelect" position="bottom" :style="{ height: '30rem' }">
        <van-picker
          style="line-height: 2rem"
          title="选择机构"
          show-toolbar
          :columns="stractColumns"
          @confirm="onConfirm2"
          @cancel="onCancel2"
        />
      </van-popup>
      <div style="position: absolute; bottom: 2rem; width: 100%">
        <el-row>
          <el-col :span="12">
            <el-button type="primary" style="float: right; margin-right: 12px" @click="saveUserEdit">保存</el-button>
          </el-col>
          <el-col :span="12">
            <el-button style="float: left; margin-left: 12px" @click="cancelSave">取消</el-button>
          </el-col>
        </el-row>
      </div>
    </van-popup>
  </div>
</template>
<script>
  import { findAllUser, findAllStruct } from '../../../api/admin'
  import { update } from '../../../api/userServices'
  export default {
    name: 'userTableView',
    components: {},
    mixins: [],
    props: {},
    data() {
      return {
        showEdit: false,
        showSlectRole: false,
        showStractSelect: false,
        userInfo: {
          id: '',
          name: '',
          roleLabel: '',
          role: '',
          structName: '',
          structId: '',
          config: '',
        },
        editList: [
          {
            label: '紧急高血糖',
            value: '',
            name: 'targetHigh',
          },
          {
            label: '紧急低血糖',
            value: '',
            name: 'targetLow',
          },
          {
            label: '高血糖预警',
            value: '',
            name: 'bgHigh',
          },
          {
            label: '低血糖预警',
            value: '',
            name: 'bgLow',
          },
        ],
        userRole: '',
        pagehelper: {
          page: 1,
          size: 20,
        },
        columns: [
          {
            text: '业务员',
            value: 'saleMan',
          },
          {
            text: '医护人员',
            value: 'doctor',
          },
          {
            text: '管理员',
            value: 'admin',
          },
          {
            text: '超级管理员',
            value: 'superAdmin',
          },
        ],
        stractColumns: [],
        name: '',
        tableData: [
          {
            id: '142f0deeeb0f46188280696a693c1f2a',
            name: '胡丙志',
            password: '********',
            createdate: '2023-06-09 14:15:10',
            images: '1',
            city: '1',
            iphone: '13155193063',
            cgm: '1',
            bizid: '1',
            role: '1',
            targetLow: '4.0',
            targetHigh: '10.0',
            gjFrist: '0',
            structId: '4135eac4c67f4dd7886eb21016a9a404',
            checked: 'true',
            config: null,
            bgLow: '4.0',
            bgHigh: '10.0',
          },
        ],
      }
    },
    computed: {},
    watch: {},
    mounted() {
      this.getAllUserInfo()
    },
    methods: {
      onSearch() {},
      goBack() {
        this.$router.push('/MineView/adminMain')
      },
      showRoleEdit() {
        this.showSlectRole = true
      },
      showStructEdit() {
        this.showStractSelect = true
      },
      getAllUserInfo() {
        findAllUser(this.pagehelper).then((res) => {
          this.tableData = res.data.data.list
        })
      },
      editUser(data) {
        this.userInfo.id = data.id
        this.editList[0].value = data.targetHigh
        this.editList[1].value = data.targetLow
        this.editList[2].value = data.bgHigh
        this.editList[3].value = data.bgLow
        this.userInfo.name = data.name
        this.userInfo.config = data.config
        this.showEdit = true
        this.stractColumns = []
        findAllStruct({ page: '1', size: '100' }).then((res) => {
          for (let index = 0; index < res.data.data.list.length; index++) {
            const element = res.data.data.list[index]
            const obj = {
              text: element.structName,
              value: element.structId,
            }
            this.stractColumns.push(obj)
          }
        })
      },
      onConfirm1(value, index) {
        this.userInfo.roleLabel = value.text
        this.userInfo.role = value.value
        this.showSlectRole = false
      },
      onConfirm2(value, index) {
        this.userInfo.structName = value.text
        this.userInfo.structId = value.value
        this.showStractSelect = false
      },
      onCancel1() {
        this.showSlectRole = false
      },
      onCancel2() {
        this.showStractSelect = false
      },
      saveUserEdit() {
        const params = {
          id: this.userInfo.id,
          structId: this.userInfo.structId,
          config: this.userInfo.config,
          role: this.userInfo.role,
          targetLow: this.editList[1].value,
          targetHigh: this.editList[0].value,
          bgLow: this.editList[3].value,
          bgHigh: this.editList[2].value,
        }
        update(params).then((res) => {
          this.$toast(res.data.msg)
          this.showEdit = false
        })
      },
      cancelSave() {
        this.showEdit = false
      },
    },
  }
</script>
<style scoped>
  #userTableView {
    font-size: 12px;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 200;
    background: #fff;
  }
  .el-icon-arrow-left {
    position: absolute;
    left: 12px;
    top: 12px;
    font-size: 2rem;
  }
</style>
