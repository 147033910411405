<template>
  <div id="wangEmitter">
    <van-nav-bar title="旺旺发射器" left-arrow @click-left="$router.go(-1)"></van-nav-bar>
    <div v-loading="loading">
      <div style="width: 100%; height: 1.2rem; padding-top: 0.15rem">
        <div style="overflow: hidden; width: 1rem; height: 1rem; margin: auto; border-radius: 0.55rem">
          <img :src="wwfsq" style="width: 1rem; height: 1rem" />
        </div>
      </div>
      <div
        v-if="unScan"
        style="
          width: 3.55rem;
          margin-left: 0.1rem;
          background: #fff;
          height: 1.5rem;
          border-radius: 0.06rem;
          text-align: left;
        "
      >
        <div class="rowStyle">
          <div>探头名称</div>
          <div></div>
        </div>
        <div class="rowStyle boderTop">
          <div>使用天数</div>
          <div></div>
        </div>
        <div class="rowStyle boderTop">
          <div>探头状态</div>
          <div></div>
        </div>
      </div>
      <div
        v-else
        style="
          width: 3.55rem;
          margin-left: 0.1rem;
          background: #fff;
          height: 4rem;
          border-radius: 0.06rem;
          text-align: left;
        "
      >
        <div class="rowStyle">
          <div>探头名称</div>
          <div class="textR">
            {{ scanerInfo.cgmName }}
          </div>
        </div>
        <div class="rowStyle boderTop">
          <div>连接时间</div>
          <div class="textR">
            {{ scanerInfo.connectionDate }}
          </div>
        </div>
        <div class="rowStyle boderTop">
          <div>探头状态</div>
          <div class="textR">
            {{ scanerInfo.connectionStatus }}
          </div>
        </div>
        <div class="rowStyle boderTop">
          <div>探头序列号</div>
          <div class="textR">
            {{ scanerInfo.driveNO }}
          </div>
        </div>
        <div class="rowStyle">
          <div>发射器类型</div>
          <div class="textR">
            {{ scanerInfo.cgmType }}
          </div>
        </div>
        <div class="rowStyle boderTop">
          <div>发射器状态</div>
          <div class="textR">
            {{ scanerInfo.driveStatus }}
          </div>
        </div>
        <div class="rowStyle boderTop">
          <div>电池电量</div>
          <div class="textR">
            {{ scanerInfo.powerPer }}
          </div>
        </div>
        <div class="rowStyle boderTop">
          <div>固件/硬件版本</div>
          <div class="textR">
            {{ scanerInfo.firmware + '-' + scanerInfo.hardware }}
          </div>
        </div>
      </div>

      <el-button
        v-if="unScan"
        @click="BBMiniConnect"
        style="
          margin-top: 0.14rem;
          background: #009944;
          color: #fff;
          width: 3.55rem;
          border-radius: 0.06rem;
          height: 0.5rem;
          font-size: 0.16rem;
        "
      >
        扫 描
      </el-button>
      <el-button
        v-else
        @click="deleteWW"
        style="
          margin-top: 0.14rem;
          background: #009944;
          color: #fff;
          width: 3.55rem;
          border-radius: 0.06rem;
          height: 0.5rem;
          font-size: 0.16rem;
        "
      >
        删 除
      </el-button>
    </div>
  </div>
</template>
<script>
  import wwfsq from '../../../static/cgm/wwfsq.png'
  import { upstateYP, getUserInfoById } from '../../../api/relationship'
  export default {
    name: 'wangEmitter',
    data() {
      return {
        wwfsq: wwfsq,
        unScan: true,
        loading: false,
        scanerInfo: {
          cgmName: 'cgmName',
          cgmAddr: 'cgmAddr',
          useDate: '',
          maxLife: '14天10时',
          connectionStatus: 'connectionStatus',
          connectionDate: 'connectionDate',
          cgmType: 'cgmType',
          driveNO: 'driveNO',
          driveStatus: 'driveStatus',
          powerPer: 'powerPer',
          firmware: 'firmware',
          hardware: 'hardware',
          status: 'status',
        },
      }
    },
    mounted() {
      window.didConnectBluetooth = this.didConnectBluetooth
      window.setDeviceInformation = this.setDeviceInformation
      window.setAndroidDeviceInformation = this.setAndroidDeviceInformation
      window.disConnectBBmini = this.disConnectBBmini
      window.disconnectDevice = this.disconnectDevice

      this.getWangCgmInfo()
    },
    methods: {
      // 获取旺旺设备信息
      getWangCgmInfo() {
        const appuser = JSON.parse(localStorage.getItem('appuser'))
        getUserInfoById(appuser.id).then((res) => {
          if (res.data.data.yp && res.data.data.cgm == 'yp') {
            console.log('获取设备信息并连接')
            const cgmInfo = JSON.parse(res.data.data.yp)
            this.scanerInfo = {
              cgmName: cgmInfo.cgmName,
              cgmAddr: cgmInfo.cgmAddr,
              // useDate: useDate,
              connectionStatus: cgmInfo.connectionStatus,
              connectionDate: cgmInfo.connectionDate,
              cgmType: cgmInfo.cgmType,
              driveNO: cgmInfo.driveNO,
              driveStatus: cgmInfo.driveStatus,
              powerPer: cgmInfo.powerPer,
              firmware: cgmInfo.firmware,
              hardware: cgmInfo.hardware,
            }
            this.unScan = false
            this.loading = false
            this.$toast('已连接')
          } else {
            return
          }
        })
      },
      goBackBlood() {
        this.$router.push({ path: '/BloodSugar', query: { index: 0 } })
      },
      BBMiniConnect() {
        const message = {
          message: '旺旺连接',
          body: {
            type: 'Bubble / Bubble Mini',
          },
        }
        const androidMessage = JSON.stringify(message)
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.openBluetooth(androidMessage)
        } else {
          window.webkit.messageHandlers.openBluetooth.postMessage(message)
        }

        this.loading = true

        setTimeout(() => {
          if (this.loading) {
            this.loading = false
            this.$toast('自动连接失败，请重试')
          }
        }, 30000)
      },
      deleteWW() {
        const message = {
          message: '断开旺旺',
          body: {
            type: 'Bubble / Bubble Mini',
          },
        }
        const androidMessage = JSON.stringify(message)
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.disconnectDevice(androidMessage)
        } else {
          window.webkit.messageHandlers.disconnectDevice.postMessage(message)
        }
        // const appuser = JSON.parse(localStorage.getItem("appuser"));
        // let params = {
        //   uid: appuser.id,
        //   yp: "",
        // };
        // upstateYP(params).then((res) => {
        //   if (res.data.code == 200 || res.data.code == "200") {
        //     this.$toast("删除成功");
        //     this.unScan = true;
        //   }
        // });
      },
      disConnectBBmini() {
        this.scanerInfo = {
          cgmName: '',
          cgmAddr: '',
          useDate: '',
          maxLife: '',
          connectionStatus: '',
          connectionDate: '',
          cgmType: '',
          driveNO: '',
          driveStatus: '',
          powerPer: '',
          firmware: '',
          hardware: '',
          status: '',
        }
        this.unScan = true
        const appuser = JSON.parse(localStorage.getItem('appuser'))
        let params = {
          uid: appuser.id,
          yp: '',
        }
        upstateYP(params).then((res = {}))
        this.$toast('删除成功')
        this.loading = false
        this.$router.push({ path: '/BloodSugar', query: { index: 0 } })
        return '确定'
      },
      setDeviceInformation(cgmInfo) {
        if (cgmInfo) {
          console.log('自动连接')
          const cgmName = cgmInfo.cgmName
          const cgmAddr = cgmInfo.cgmAddr
          const connectionStatus = cgmInfo.connectionStatus
          const connectionDate = cgmInfo.connectionDate
          const cgmType = cgmInfo.cgmType
          const driveNO = cgmInfo.driveNO
          const driveStatus = cgmInfo.driveStatus
          const powerPer = cgmInfo.powerPer
          const firmware = cgmInfo.firmware
          const hardware = cgmInfo.hardware
          const status = driveNO ? '已连接' : cgmType ? '已连接' : cgmAddr ? '已连接' : '未连接'
          this.scanerInfo = {
            cgmName: cgmName,
            cgmAddr: cgmAddr,
            // useDate: useDate,
            connectionStatus: connectionStatus,
            connectionDate: connectionDate,
            cgmType: cgmType,
            driveNO: driveNO,
            driveStatus: driveStatus,
            powerPer: powerPer,
            firmware: firmware,
            hardware: hardware,
            status: status,
          }
          this.unScan = false
          this.loading = false
          this.$toast('自动连接成功')

          const appuser = JSON.parse(localStorage.getItem('appuser'))
          let params = {
            uid: appuser.id,
            yp: JSON.stringify(cgmInfo),
          }
          upstateYP(params).then((res = {}))

          this.$router.push({ path: '/BloodSugar', query: { index: 0 } })
        } else {
          this.BBMiniConnect()
        }
        // this.loading = false;
        // this.$router.push({ path: "/BloodSugar", query: { index: 0 } });
      },
      setAndroidDeviceInformation(info) {
        const cgmInfo = JSON.parse(JSON.stringify(info))
        if (cgmInfo) {
          console.log('自动连接')
          const cgmName = cgmInfo.cgmName
          const cgmAddr = cgmInfo.cgmAddr
          const connectionStatus = cgmInfo.connectionStatus
          const connectionDate = cgmInfo.connectionDate
          const cgmType = cgmInfo.cgmType
          const driveNO = cgmInfo.driveNO
          const driveStatus = cgmInfo.driveStatus
          const powerPer = cgmInfo.powerPer
          const firmware = cgmInfo.firmware
          const hardware = cgmInfo.hardware
          const status = driveNO ? '已连接' : cgmType ? '已连接' : cgmAddr ? '已连接' : '未连接'
          this.scanerInfo = {
            cgmName: cgmName,
            cgmAddr: cgmAddr,
            // useDate: useDate,
            connectionStatus: connectionStatus,
            connectionDate: connectionDate,
            cgmType: cgmType,
            driveNO: driveNO,
            driveStatus: driveStatus,
            powerPer: powerPer,
            firmware: firmware,
            hardware: hardware,
            status: status,
          }
          this.unScan = false
          this.loading = false
          this.$toast('自动连接成功')

          const appuser = JSON.parse(localStorage.getItem('appuser'))
          let params = {
            uid: appuser.id,
            yp: JSON.stringify(cgmInfo),
          }
          upstateYP(params).then((res = {}))

          this.$router.push({ path: '/BloodSugar', query: { index: 0 } })
        } else {
          this.BBMiniConnect()
        }
        return '确定'
      },

      didConnectBluetooth() {
        this.loading = false
        this.$router.push({ path: '/BloodSugar', query: { index: 0 } })
      },
    },
  }
</script>
<style scoped>
  #wangEmitter {
    padding-top: 0.62rem;
    background: #e7e9f3;
    min-height: calc(100vh - 0.62rem);
  }
  .boderTop {
    border-top: 1px solid #eee;
  }
  .rowStyle {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: 5%;
    height: 0.5rem;
    line-height: 0.5rem;
    font-size: 0.14rem;
    color: #292929;
  }
  .textR {
    text-align: right;
  }
</style>
