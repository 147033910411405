import axios from 'axios'
// const token = axios.defaults.headers.token ? axios.defaults.headers.token : localStorage.getItem('token') ? localStorage.getItem('token') : ''
const api = axios.create({
  // headers: {
  //   token: token
  // },
  // baseURL: 'http://192.168.110.50:20027', // 请求的公共地址部分
  timeout: 20000, // 请求超时时间 当请求时间超过12秒还未取得结果时 提示用户请求超时
})
// interceptors axios的拦截器对象
api.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = window.localStorage.getItem('token')
    if (token) {
      config.headers.token = token
      // config.headers['accessToken'] = Token;
      return config
    }
    return config
  },
  (err) => {
    // 请求发生错误时的处理 抛出错误
    Promise.reject(err)
  }
)
api.interceptors.response.use(
  (res) => {
    // 我们一般在这里处理，请求成功后的错误状态码 例如状态码是500，404，403
    // res 是所有相应的信息
    return Promise.resolve(res)
  },
  (err) => {
    // 服务器响应发生错误时的处理
    return Promise.reject(err)
  }
)
export default api
