<template>
  <div id="CGMDetails" v-touch:swipe="swipeHandler">
    <!-- <div class="dialogDelete" v-if="showDelete">
      <div style="width: 100%; height: 0.84rem; color: #fff; margin-top: 0.12rem">
        <div style="width: 100%; height: 0.96rem; line-height: 0.96rem; font-size: 0.18rem">删除蓝牙设备吗？</div>
        <div style="width: 100%; height: 0.8rem; line-height: 0.8rem; font-size: 0.14rem">
          {{ bindingCGM.cgmName }}
        </div>
      </div>
      <div style="width: 100%; height: 0.48rem; border-top: 1px solid #000">
        <div class="dialogBotton" style="background: #ea2d63" @click="deleteBluetooth">删除</div>
        <div class="dialogBotton" @click="cancelDelete">取消</div>
      </div>
    </div> -->
    <van-popup v-model="showDelete">
      <div class="body">
        <div>确定删除蓝牙设备吗？</div>
        <div>
          {{ bindingCGM.cgmName }}
        </div>
      </div>
      <div class="foot flex">
        <div @click="cancelDelete">取消</div>
        <div @click="deleteBluetooth">删除</div>
      </div>
    </van-popup>
    <div v-loading="loading" style="padding-bottom: 0.3rem">
      <van-nav-bar title="详情" left-arrow @click-left="goBack">
        <template #right>
          <van-icon @click="showDeleteBluetooth" name="delete-o" color="#ee0a24" />
        </template>
      </van-nav-bar>

      <!-- 图片 -->
      <div style="margin: 0.18rem auto">
        <img :src="wwBig" style="width: 1.34rem; height: 1.34rem" />
      </div>
      <div class="content">
        <div class="flex-b">
          <span>名称</span>
          <span>{{ bindingCGM.cgmName }}</span>
        </div>
        <div class="flex-b">
          <span>连接时间</span>
          <span>{{ bindingCGM.connectionDate }}</span>
        </div>
        <div class="flex-b" @click="changeAccount()">
          <span>状态</span>
          <span>{{ bindingCGM.connectionStatus }}</span>
        </div>
      </div>
      <!-- <div
        style="
          width: 3.55rem;
          margin: 0 auto;
          height: 1.56rem;
          background: #131731;
          padding-top: 0.12rem;
          border-radius: 0.12rem;
        "
      >
        <el-row style="height: 0.48rem; line-height: 0.48rem; font-size: 0.19rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 0.12rem">名称</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 0.12rem">
            {{ bindingCGM.cgmName }}
          </el-col>
        </el-row>
        <el-row style="height: 0.48rem; line-height: 0.48rem; font-size: 0.19rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 0.12rem">连接时间</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 0.12rem">
            {{ bindingCGM.connectionDate }}
          </el-col>
        </el-row>
        <div @click="changeAccount()" style="height: 0.48rem; line-height: 0.48rem; font-size: 0.19rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 0.12rem">状态</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 0.12rem">
            {{ bindingCGM.connectionStatus }}
          </el-col>
        </div>
      </div> -->

      <div class="content" style="height: 3.24rem">
        <div class="flex-b">
          <span>传感器类型</span>
          <span>{{ bindingCGM.cgmType }}</span>
        </div>
        <div class="flex-b">
          <span>传感器序列号</span>
          <span>{{ bindingCGM.driveNO }}</span>
        </div>
        <div class="flex-b">
          <span>发射器状态</span>
          <span>{{ bindingCGM.driveStatus }}</span>
        </div>
        <div class="flex-b">
          <span>电池级别</span>
          <span>{{ bindingCGM.powerPer }}</span>
        </div>
        <div class="flex-b">
          <span>固件</span>
          <span>{{ bindingCGM.firmware }}</span>
        </div>
        <div class="flex-b">
          <span>硬件</span>
          <span>{{ bindingCGM.hardware }}</span>
        </div>
      </div>

      <!-- <div
        style="
          width: 88%;
          margin-top: 0.12rem;
          margin-bottom: 0.48rem;
          margin-left: 6%;
          height: 2.88rem;
          background: #131731;
          padding-top: 0.12rem;
          border-radius: 0.12rem;
        "
      >
        <el-row style="height: 0.48rem; line-height: 0.48rem; font-size: 0.19rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 0.12rem">传感器类型</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 0.12rem">
            {{ bindingCGM.cgmType }}
          </el-col>
        </el-row>
        <el-row style="height: 0.48rem; line-height: 0.48rem; font-size: 0.19rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 0.12rem">传感器序列号</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 0.12rem">
            {{ bindingCGM.driveNO }}
          </el-col>
        </el-row>
        <el-row style="height: 0.48rem; line-height: 0.48rem; font-size: 0.19rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 0.12rem">发射器状态</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 0.12rem">
            {{ bindingCGM.driveStatus }}
          </el-col>
        </el-row>
        <el-row style="height: 0.48rem; line-height: 0.48rem; font-size: 0.19rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 0.12rem">电池级别</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 0.12rem">
            {{ bindingCGM.powerPer }}
          </el-col>
        </el-row>
        <el-row style="height: 0.48rem; line-height: 0.48rem; font-size: 0.19rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 0.12rem">固件</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 0.12rem">
            {{ bindingCGM.firmware }}
          </el-col>
        </el-row>
        <el-row style="height: 0.48rem; line-height: 0.48rem; font-size: 0.19rem">
          <el-col :span="12" style="text-align: left; color: #fff; padding-left: 0.12rem">硬件</el-col>
          <el-col :span="12" style="text-align: right; color: #aaa; padding-right: 0.12rem">
            {{ bindingCGM.hardware }}
          </el-col>
        </el-row>
      </div> -->
    </div>
    <!-- <div style="top: 0%; background: rgb(255, 255, 255); opacity: 0.5;
                    width: 100%; height: 100%; position: fixed;">
            <div>
            </div>
        </div> -->
  </div>
</template>
<script>
  import { upstateYP, getUserInfoById } from '../../../api/relationship'
  import wwBig from '../../../static/cgm/wwBig.png'
  import { Notify, Dialog } from 'vant'
  export default {
    name: 'CGMDetails',
    props: {
      // bindingCGM: {
      //   type: Object,
      //   default: function () {
      //     return {}
      //   },
      // },
    },
    data() {
      return {
        wwBig: wwBig,
        loading: false,

        num: 0, //点击次数
        timer: '', //第一次点击的时间
        timer6: '', //第六次点击的时间

        bindingCGM: {
          cgmName: '',
          cgmAddr: '',
          useDate: '',
          maxLife: '',
          connectionStatus: '',
          connectionDate: '',
          cgmType: '',
          driveNO: '',
          driveStatus: '',
          powerPer: '',
          firmware: '',
          hardware: '',
          status: '',
        },
        showDelete: false,
      }
    },
    mounted() {
      window.setDeviceInformation = this.setDeviceInformation
      window.setAndroidDeviceInformation = this.setAndroidDeviceInformation
      window.disconnectDevice = this.disconnectDevice
      window.libreRestart = this.libreRestart

      this.getWangCgmInfo()
    },
    methods: {
      // 获取旺旺设备信息
      getWangCgmInfo() {
        const appuser = JSON.parse(localStorage.getItem('appuser'))
        getUserInfoById(appuser.id).then((res) => {
          if (res.data.data.yp && res.data.data.cgm == 'yp') {
            console.log('获取设备信息并连接')
            const cgmInfo = JSON.parse(res.data.data.yp)
            this.bindingCGM = {
              cgmName: cgmInfo.cgmName,
              cgmAddr: cgmInfo.cgmAddr,
              // useDate: useDate,
              connectionStatus: cgmInfo.connectionStatus,
              connectionDate: cgmInfo.connectionDate,
              cgmType: cgmInfo.cgmType,
              driveNO: cgmInfo.driveNO,
              driveStatus: cgmInfo.driveStatus,
              powerPer: cgmInfo.powerPer,
              firmware: cgmInfo.firmware,
              hardware: cgmInfo.hardware,
            }
            this.unScan = false
            this.loading = false
            this.$toast('已连接')
          } else {
            return
          }
        })
      },
      //  连续6次点击显示弹框
      changeAccount() {
        this.num++
        if (this.num == 1) {
          this.timer = new Date().getTime() / 1000
        }
        if (this.num == 3) {
          this.timer6 = new Date().getTime() / 1000
          if (this.timer6 - this.timer <= 2) {
            this.num = 0
            const message = {
              message: '雅培重启',
              body: {},
            }
            const androidMessage = JSON.stringify(message)
            const phoneType = localStorage.getItem('phoneType')
            if (phoneType == 'Android') {
              window.android.libreRestart(androidMessage)
            } else {
              window.webkit.messageHandlers.libreRestart.postMessage(message)
            }
          } else {
            this.num = 0
          }
        }
      },
      goBack() {
        this.$router.push('/MineView')
      },
      swipeHandler(direction) {
        if (direction === 'left') {
          this.$emit('moveDirection', 'left')
        } else if (direction === 'right') {
          this.$router.push({ path: '/', query: { index: 0 } })
        }
      },
      showDeleteBluetooth() {
        this.showDelete = true
      },
      deleteBluetooth() {
        this.showDelete = false
        const message = {
          message: '删除蓝牙',
          body: {},
        }
        const androidMessage = JSON.stringify(message)
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.disconnectDevice(androidMessage)
        } else {
          window.webkit.messageHandlers.disconnectDevice.postMessage(message)
        }

        this.$toast('删除成功')
      },
      cancelDelete() {
        this.showDelete = false
      },
      setDeviceInformation(device) {
        this.$emit('editBindingCGM', device)
      },
      setAndroidDeviceInformation(device) {
        const info = JSON.parse(JSON.stringify(device))
        this.$emit('editBindingCGM', info)
        return '确定'
      },
    },
  }
</script>
<style scoped lang="less">
  #CGMDetails {
    padding-top: 0.62rem;
    width: 100%;
    min-height: calc(100vh - 0.62rem);
    background: #21264a;
    z-index: 100;
    position: absolute;
    top: 0;
    background: #e7e9f3;
  }
  .dialogDelete {
    width: 80%;
    height: 1.44rem;
    background: #f8f8f8;
    position: absolute;
    z-index: 2001;
    overflow: hidden;
    left: 10%;
    top: 40%;
    border-radius: 0.12rem;
  }
  .dialogBotton {
    width: 50%;
    height: 100%;
    float: left;
    line-height: 0.48rem;
    font-size: 0.144rem;
    color: #292929;
  }
  .content {
    box-sizing: border-box;
    margin: 0 auto 0.1rem;
    padding-left: 0.2rem;
    width: 3.55rem;
    height: 1.61rem;
    background: #ffffff;
    border-radius: 0.06rem;
    & > div {
      height: 0.53rem;
      border-bottom: 1px solid #e8e8e8;
      font-size: 0.14rem;
      color: #292929;
      &:last-of-type {
        border-bottom: 0;
      }
      span:last-of-type {
        margin-right: 0.15rem;
      }
    }
  }
  .van-popup {
    width: 2rem;
    height: 1.4rem;
    border-radius: 0.1rem;
    overflow: hidden;
    .body {
      width: 100%;
      height: 1rem;
      line-height: 1rem;
    }
    .foot {
      height: 0.4rem;
      & > div {
        height: 100%;
        width: 50%;
        text-align: center;
        line-height: 0.4rem;
        background-color: #009944;
        color: #fff;
      }
    }
  }
  .van-icon {
    font-size: 0.15rem;
  }
  ::v-deep .van-nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.62rem;

    .van-nav-bar__content {
      height: 0.62rem;

      .van-nav-bar__left {
        .van-icon {
          font-size: 0.2rem;
          color: #292929;
        }
      }

      .van-nav-bar__title {
        font-size: 0.16rem;
        height: 0.62rem;
        line-height: 0.62rem;
        color: #009944;
      }
    }
  }
</style>
