<template>
  <div class="common-box">
    <!-- 标题 -->
    <div class="title" v-show="isShow">
      <img src="@/static/pic/daysIcon.png" alt="" />
      <span>多日血糖曲线</span>
    </div>
    <!-- 散点图 -->
    <div :id="title" :class="title == 'chart1' || title == 'sugarWeek' ? 'chart1' : 'chart2'"></div>
    <!-- <div
      class="vertical1"
      v-show="title == 'chart1' || title == 'sugarWeek'"
    ></div>
    <div
      class="vertical2"
      v-show="title == 'chart1' || title == 'sugarWeek'"
    ></div> -->
  </div>
</template>
<script>
  import { Line } from '@antv/g2plot'
  import { getBGbyToken, getSugerbyId } from '@/api/userServices'
  export default {
    props: {
      userId: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: 'chart1',
      },
      endDays: {
        type: String,
        default: '0',
      },
      startDays: {
        type: String,
        default: '6',
      },
      isShow: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        scatterPlot: null,
        chartData: [],
        // 时间段选择
        time: 3,
        averageSuger: '',
        standardError: '',
        CV: '',
        sugarNum: '',
        overRange: '',
      }
    },
    created() {
      setTimeout(() => {
        this.getChart()
      }, 300)

      if (this.$route.query.id) {
        this.getDateById()
      } else {
        this.getDateArray()
      }
    },
    mounted() {},
    methods: {
      getChart() {
        let data = this.chartData
        this.scatterPlot = new Line(this.title, {
          appendPadding: [0, 10, 10, 10],
          data,
          xField: 'create_time',
          yField: 'v',
          seriesField: 'tooltip_time',
          lineStyle: {
            lineWidth: 1, // 设置线条的宽度为 1
          },
          state: {
            active: {
              style: {
                lineWidth: 1, // 选中曲线时的宽度
              },
            },
          },
          xAxis: {
            type: 'time',
            max: this.maxTime,
            min: this.minTime,
            mask: 'HH:mm', // 显示格式为小时:分钟
            tickMethod: (scale) => {
              const { min, max, tickCount } = scale
              const ticks = []
              for (let i = min; i <= max; i += 3 * 60 * 60 * 1000) {
                ticks.push(i)
              }
              return ticks
            },
          },
          yAxis: {
            line: null,
            tickMethod: () => [0, 5, 10, 15, 22],
          },
          legend: {
            position: 'top-right', // 将图例放置在右上角
            // layout: 'horizontal',
            itemName: {
              style: {
                fontSize: 13, // 调整图例文字的字体大小
                fontWeight: 'normal',
              },
            },
            itemSpacing: 4, // 调整图例项之间的间距
            marker: {
              symbol: 'hyphen', // 图例标记的形状
            },
            flipPage: false,
          },
          smooth: true,
          // @TODO 后续会换一种动画方式
          animation: {
            appear: {
              animation: 'path-in',
              duration: 2000,
            },
          },
          annotations: [
            {
              type: 'line',
              start: ['min', 3.9],
              end: ['max', 3.9],
              style: {
                stroke: '#ff1b0c',
                lineWidth: 1,
              },
            },
            {
              type: 'line',
              start: ['min', 10],
              end: ['max', 10],
              style: {
                stroke: '#ffa43c',
                lineWidth: 1,
              },
            },
            {
              type: 'text',
              position: ['max', 10],
              content: '10',
              offsetY: 8,
              style: {
                textBaseline: 'bottom',
                fill: '#ffa43c',
              },
            },
            {
              type: 'text',
              position: ['max', 3.9],
              content: '3.9',
              offsetY: 8,
              style: {
                textBaseline: 'bottom',
                fill: '#ff1b0c',
              },
            },
          ],
        })
        this.scatterPlot.render()
      },

      getDateArray() {
        this.chartData = []
        const params = {
          end: this.$moment()
            .subtract(this.endDays * 1, 'day')
            .format('YYYY-MM-DD 23:59:59'),
          start: this.$moment()
            .subtract(this.startDays * 1, 'day')
            .format('YYYY-MM-DD 00:00:00'),
        }
        getBGbyToken(params).then((res) => {
          const today = this.$moment().format('YYYY-MM-DD')
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach((item) => {
              this.chartData.push({
                create_time: today + ' ' + item.create_time.substring(11, 16),
                v: item.v,
                tooltip_time: item.create_time.substring(5, 10),
              })
            })
          }
        })
      },

      getDateById() {
        this.chartData = []
        const params = {
          id: this.$route.query.patient_id,
          end: this.$moment()
            .subtract(this.endDays * 1, 'day')
            .format('YYYY-MM-DD 23:59:59'),
          start: this.$moment()
            .subtract(this.startDays * 1, 'day')
            .format('YYYY-MM-DD 00:00:00'),
        }
        getSugerbyId(params).then((res) => {
          let n = 0
          let square = 0
          const today = this.$moment().format('YYYY-MM-DD')
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach((item) => {
              this.chartData.push({
                create_time: today + ' ' + item.create_time.substring(11, 16),
                v: item.v,
                tooltip_time: item.create_time.substring(5, 10),
              })
              n += item.v
            })
            const arrLength = res.data.data.length
            // 平均血糖
            this.averageSuger = (n / arrLength).toFixed(1)
            // 标准偏差SD
            res.data.data.forEach((item) => {
              const mis = Math.max(item.v, this.averageSuger) - Math.min(item.v, this.averageSuger)
              const multiply = mis * mis
              square += multiply
            })
            const num = square / arrLength // 平方和÷数据总数
            this.standardError = Math.sqrt(num).toFixed(2) // 开方
            // 变异系数CV
            this.CV = ((this.standardError / this.averageSuger) * 100).toFixed(0)
            // 探头读数
            this.sugarNum = arrLength
            // 覆盖范围
            this.overRange = ((arrLength / 2016) * 100).toFixed(0)
            this.$emit(
              'getAverageSuger',
              this.averageSuger,
              this.standardError,
              this.CV,
              this.sugarNum,
              this.overRange,
              this.endDays
            )
          }
        })
      },
    },
    computed: {
      maxTime() {
        const nextTime = this.$moment().add(1, 'days').format('YYYY-MM-DD 00:00:00')
        return new Date(nextTime).getTime()
      },
      minTime() {
        const min = this.$moment().format('YYYY-MM-DD 00:00:00')
        return new Date(min).getTime()
      },
    },
    watch: {
      chartData: {
        handler: function (newVal, oldVal) {
          if (this.scatterPlot) {
            this.$nextTick(() => {
              this.scatterPlot.destroy()
              this.getChart()
            })
          }
        },
      },
    },
  }
</script>
<style scoped lang="less">
  .common-box {
    width: 3.55rem;
    margin: 0.08rem 0.1rem;
    padding-top: 0.04rem;
    border-radius: 0.06rem;
    background: #fff;
    position: relative;
  }
  .van-radio-group--horizontal {
    justify-content: center;
  }
  .chartCan {
    width: 100%;
    background: #d3eeff;
    position: absolute;
    border-top: 2px dotted #c1b8b8;
    border-bottom: 2px dotted #c1b8b8;
    height: 0.045rem;
    bottom: 0.094rem;
  }
  .bottom-num {
    position: absolute;
    right: 5px;
    bottom: -16px;
    color: red;
  }
  .top-num {
    position: absolute;
    right: 5px;
    top: -16px;
    color: orange;
  }
  .unit {
    position: absolute;
    left: 5px;
    margin-top: 5px;
  }
  .van-radio__label {
    color: #7accff;
  }
  .chart1 {
    width: 100%;
    /* height: 3.8rem; */
    height: 2.4rem;
  }
  .chart2 {
    width: 450px;
    height: 1.6rem;
  }
  /* .vertical1 {
    position: absolute;
    top: 100rem;
    left: 25px;
    width: 0;
    height: 213rem;
    border: 1px solid #b2b2b2;
  }
  .vertical2 {
    position: absolute;
    top: 100rem;
    right: 18px;
    width: 0;
    height: 213rem;
    border: 1px solid #b2b2b2;
  } */
  /* .title {
    height: 0.41rem;
    font-size: 0.14rem;
    font-weight: 700;
    text-align: start;
    line-height: 0.41rem;
    padding-left: 0.12rem;
  } */
  .title {
    display: flex;
    align-items: center;
    font-size: 0.13rem;
    height: 0.4rem;
    color: #848894;
    font-weight: normal;
    img {
      margin: 0 0.05rem 0 0.11rem;
      width: 0.18rem;
      height: 0.18rem;
    }
    span {
      height: 0.4rem;
      line-height: 0.4rem;
    }
  }
</style>
