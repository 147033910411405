<template>
  <div id="ydView">
    <div class="topHeader">
      <i class="el-icon-arrow-left" @click="goBack"></i>
      <div class="icon flex">
        <img src="@/static/pic/ydIcon.png" alt="" style="width: 0.29rem; height: 0.29rem; margin-right: 0.1rem" />
        <span style="color: #fff">添加运动</span>
      </div>
    </div>
    <div class="body">
      <van-cell title="时间" is-link :value="selectTime" @click="openSelectTime" class="must" />
      <!-- <van-cell title="结束时间" is-link :value="endTime" @click="openEndTime" /> -->
      <van-field
        label="运动时长（分钟）"
        input-align="right"
        v-model="endTime"
        placeholder="请输入/分钟"
        class="must"
      />
      <van-field label="备注" input-align="right" v-model="notes" placeholder="请输入" class="must" />
      <van-field
        v-model="kcal"
        input-align="right"
        type="number"
        label="消耗热量（kcal）"
        placeholder="请输入"
        class="must"
      />
    </div>
    <el-col class="save">
      <el-button @click="addyd" class="bottomBtn">
        <img src="@/static/pic/addBtn.png" alt="" style="width: 0.19rem; height: 0.19rem; margin-right: 0.09rem" />
        添 加
      </el-button>
    </el-col>
    <van-popup v-model="showTimeSelect" position="bottom" :style="{ height: '40%' }">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择完整时间"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="saveTime"
        :formatter="formatter"
      />
    </van-popup>
    <!-- <van-popup v-model="showEndTime" position="bottom" :style="{ height: '40%' }">
            <van-datetime-picker v-model="EndDate" type="datetime" title="选择完整时间" :min-date="minDate"
                :max-date="maxDate" @confirm="saveEnd" :formatter="formatter" />
        </van-popup> -->
  </div>
</template>
<script>
  import { addEvent } from '@/api/userServices'
  export default {
    name: 'ydView',
    components: {},
    mixins: [],
    props: {},
    data() {
      return {
        notes: '',
        kcal: '',
        showTimeSelect: false,
        showEndTime: false,
        selectTime: this.getFormatDate(new Date()),
        // endTime: this.getFormatDate(new Date()),
        endTime: '',
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(2025, 10, 1),
        currentDate: new Date(),
        EndDate: '',
      }
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      openSelectTime() {
        this.showTimeSelect = true
      },
      openEndTime() {
        this.showEndTime = true
      },
      saveTime(value) {
        this.selectTime = this.getFormatDate(value)
        this.showTimeSelect = false
      },
      saveEnd(value) {
        this.endTime = this.getFormatDate(value)
        this.showEndTime = false
      },
      formatter(type, val) {
        if (type === 'year') {
          return val + '年'
        }
        if (type === 'month') {
          return val + '月'
        }
        if (type === 'day') {
          return val + '日'
        }
        if (type === 'hour') {
          return val + '时'
        }
        if (type === 'minute') {
          return val + '分'
        }
        return val
      },
      getFormatDate(time) {
        const date = new Date(time)
        const year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let hour = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        month = month < 10 ? '0' + month : month
        day = day < 10 ? '0' + day : day
        hour = hour < 10 ? '0' + hour : hour
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds
        const currentDate = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds
        return currentDate
      },
      addyd() {
        const params = {
          type: 'yd',
          createTime: this.selectTime,
          scene: this.endTime,
          way: this.notes,
          slopeOrdinal:
            localStorage.getItem('slopeOrdinal') == 'undefined' || !localStorage.getItem('slopeOrdinal')
              ? 3
              : localStorage.getItem('slopeOrdinal'),
          value: localStorage.getItem('bloodSuger') ? localStorage.getItem('bloodSuger') : '5',
          eventValue: this.kcal,
        }

        addEvent(params).then((res) => {
          if (res.data.code === '200') {
            this.$toast('添加成功!')
            this.$router.go(-1)
          } else {
            this.$toast('添加失败，请输入完整信息！')
          }
        })
        // }
      },
    },
  }
</script>
<style scoped lang="less">
  #ydView {
    box-sizing: border-box;
    padding-bottom: 0.7rem;
    width: 100vw;
    height: 100vh;
    background-color: #e7e9f3;
    overflow: auto;
  }

  .topHeader {
    position: relative;
    background-color: #0198ff;
    height: 1.26rem;
  }
  .el-icon-arrow-left {
    position: absolute;
    top: 0.22rem;
    left: 0.18rem;
    font-size: 0.24rem;
    color: #fff;
    height: 0;
    width: 0;
  }
  .icon {
    position: absolute;
    left: 0.19rem;
    bottom: 0.29rem;
  }
  .body {
    position: relative;
    margin-top: -0.13rem;
    padding: 0 0.1rem;
    background-color: #e7e9f3;
    border-radius: 0.1rem 0.1rem 0 0;
    overflow: hidden;
  }
  .van-cell {
    display: flex;
    align-items: center;
    border-radius: 0.06rem;
    height: 0.51rem;
    padding: 0 0.16rem;
    font-size: 0.13rem;
    border-radius: 0.06rem;
    margin-top: 0.13rem;
    ::v-deep .van-cell__title {
      span {
        display: block;
        width: 1.2rem;
        color: #848894;
      }
    }
    ::v-deep .van-cell__value {
      span {
        height: 0.16rem;
        line-height: 0.16rem;
      }
    }
  }
  .save {
    button {
      position: fixed;
      bottom: 0.14rem;
      left: 0.1rem;
      width: 3.55rem;
      height: 0.51rem;
      background-color: #009944;
      color: #fff;
      font-size: 0.16rem;
    }
  }
  .mainBody {
    border-radius: 0.06rem;
    overflow: hidden;
  }
  ::v-deep .van-ellipsis {
    height: 20px;
    line-height: 20px;
  }
  .van-icon {
    &::before {
      width: 0.1rem;
      font-size: 0.16rem;
    }
  }
  .must {
    &::before {
      content: '*';
      color: red;
      margin-right: 0.03rem;
    }
  }
</style>
