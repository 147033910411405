<template>
  <svg
    id="svg"
    width="100%"
    height="100%"
    viewBox="0 0 1440 400"
    xmlns="http://www.w3.org/2000/svg"
    class="transition duration-300 ease-in-out delay-150"
  >
    <path
      d="M 0,400 C 0,400 0,200 0,200 C 71.55980861244021,216.3062200956938 143.11961722488041,232.61244019138758 251,222 C 358.8803827751196,211.38755980861242 503.08133971291863,173.8564593301435 622,171 C 740.9186602870814,168.1435406698565 834.555023923445,199.9617224880383 909,202 C 983.444976076555,204.0382775119617 1038.6985645933014,176.29665071770333 1124,171 C 1209.3014354066986,165.70334928229667 1324.6507177033493,182.85167464114835 1440,200 C 1440,200 1440,400 1440,400 Z"
      stroke="none"
      stroke-width="0"
      fill="#33ad69"
      class="transition-all duration-300 ease-in-out delay-150 path-0"
    ></path>

    <path
      d="M 0,400 C 0,400 0,200 0,200 C 71.55980861244021,216.3062200956938 143.11961722488041,232.61244019138758 251,222 C 358.8803827751196,211.38755980861242 503.08133971291863,173.8564593301435 622,171 C 740.9186602870814,168.1435406698565 834.555023923445,199.9617224880383 909,202 C 983.444976076555,204.0382775119617 1038.6985645933014,176.29665071770333 1124,171 C 1209.3014354066986,165.70334928229667 1324.6507177033493,182.85167464114835 1440,200 C 1440,200 1440,400 1440,400 Z"
      stroke="none"
      stroke-width="0"
      fill="#fff"
      fill-opacity="0.53"
      class="transition-all duration-300 ease-in-out delay-150 path-0"
    >
      <animate
        attributeName="d"
        values="
        M 0,600 L 0,150 C 67.2153110047847,177.21531100478467 134.4306220095694,204.43062200956936 237,211 C 339.5693779904306,217.56937799043064 477.4928229665072,203.4928229665072 573,205 C 668.5071770334928,206.5071770334928 721.5980861244018,223.5980861244019 820,199 C 918.4019138755982,174.4019138755981 1062.1148325358852,108.11483253588517 1173,93 C 1283.8851674641148,77.88516746411483 1361.9425837320573,113.94258373205741 1440,150 L 1440,600 L 0,600 Z;
        M 0,600 L 0,150 C 128.1531100478469,150.64114832535887 256.3062200956938,151.2822966507177 336,160 C 415.6937799043062,168.7177033492823 446.92822966507174,185.51196172248802 547,197 C 647.0717703349283,208.48803827751198 815.980861244019,214.66985645933016 917,207 C 1018.019138755981,199.33014354066984 1051.1483253588517,177.80861244019138 1127,166 C 1202.8516746411483,154.19138755980862 1321.4258373205741,152.0956937799043 1440,150 L 1440,600 L 0,600 Z;
        M 0,600 L 0,150 C 63.19617224880383,161.63636363636363 126.39234449760767,173.27272727272728 237,180 C 347.60765550239233,186.72727272727272 505.62679425837325,188.54545454545453 607,159 C 708.3732057416267,129.45454545454547 753.1004784688996,68.54545454545455 843,77 C 932.8995215311004,85.45454545454545 1067.9712918660287,163.27272727272728 1175,187 C 1282.0287081339713,210.72727272727272 1361.0143540669856,180.36363636363637 1440,150 L 1440,600 L 0,600 Z;
        M 0,600 L 0,150 C 97.70334928229664,107.34928229665071 195.40669856459328,64.69856459330143 287,83 C 378.5933014354067,101.30143540669857 464.07655502392345,180.55502392344496 561,204 C 657.9234449760766,227.44497607655504 766.287081339713,195.08133971291866 877,158 C 987.712918660287,120.91866028708134 1100.7751196172248,79.11961722488039 1195,77 C 1289.2248803827752,74.88038277511961 1364.6124401913876,112.4401913875598 1440,150 L 1440,600 L 0,600 Z;
        M 0,600 L 0,150 C 67.2153110047847,177.21531100478467 134.4306220095694,204.43062200956936 237,211 C 339.5693779904306,217.56937799043064 477.4928229665072,203.4928229665072 573,205 C 668.5071770334928,206.5071770334928 721.5980861244018,223.5980861244019 820,199 C 918.4019138755982,174.4019138755981 1062.1148325358852,108.11483253588517 1173,93 C 1283.8851674641148,77.88516746411483 1361.9425837320573,113.94258373205741 1440,150 L 1440,600 L 0,600 Z"
        dur="4s"
        repeatCount="indefinite"
        keyTimes="0;0.25;0.5;0.75;1"
      ></animate>
    </path>
  </svg>
</template>

<script>
  export default {
    data() {
      return {
        animation: false,
      }
    },
    mounted() {
      setTimeout(() => {
        this.animation = true
      }, 100)
    },
  }
</script>

<style lang="less" scoped>
  svg {
    vertical-align: middle;
    background-color: #009944;
  }
</style>
