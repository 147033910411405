<template>
  <div id="QRCBind">
    <div class="topBorder">
      <el-card class="box-card">
        <div style="height: 8rem; line-height: 2rem">
          <div style="width: 6rem; height: 6rem; margin-left: 1rem; border-radius: 8px; overflow: hidden; float: left">
            <img style="width: 6rem; height: 6rem" :src="headSculptureUrl" />
          </div>
          <div style="text-align: left; margin-left: 1rem; width: 6rem; height: 4rem; float: left">
            <div style="height: 2rem; line-height: 2rem; font-size: 1.2rem">
              {{ info.name }}
              <i class="el-icon-user-solid"></i>
            </div>
            <div style="height: 2rem; line-height: 2rem; font-size: 0.8rem">角色: {{ info.role }}</div>
            <div style="height: 2rem; line-height: 2rem; font-size: 0.8rem">地区: {{ info.city }}</div>
          </div>
        </div>
      </el-card>
      <el-button
        v-if="qrcByDoctor"
        style="width: 18rem; height: 3rem; margin-top: 4rem; color: #008bff; font-weight: bold"
        @click="addRelationship"
      >
        添加到名下
      </el-button>
      <el-button
        v-if="!qrcByDoctor"
        style="width: 18rem; height: 3rem; margin-top: 4rem; color: #008bff; font-weight: bold"
      >
        加入该医生
      </el-button>
      <el-button
        style="width: 18rem; height: 3rem; margin-top: 1rem; margin-left: 0; color: #008bff; font-weight: bold"
        @click="colseAndBack"
      >
        取消并返回
      </el-button>
    </div>
  </div>
</template>
<script>
  import { getUserInfoById, insertRelation } from '../../api/relationship'
  export default {
    name: 'QRCBind',
    components: {},
    mixins: [],
    props: {},
    data() {
      return {
        relationship: {
          mangerId: '',
          mangerName: '',
          bemangeredId: '',
          bemangeredName: '',
        },
        info: {},
        qrcByDoctor: true, // true医生扫病人
        headSculptureUrl: '',
      }
    },
    computed: {},
    watch: {},
    mounted() {
      this.initRelationship()
    },
    methods: {
      colseAndBack() {
        this.$router.push('/MineView/PersonnelManagement')
      },
      initRelationship() {
        const query = this.$route.query
        if (!query) {
          return
        }
        if (query.mangerId) {
          this.relationship.mangerId = query.mangerId
          this.relationship.mangerName = query.mangerName
        }
        if (query.bemangeredId) {
          this.relationship.bemangeredId = query.bemangeredId
          this.relationship.bemangeredName = query.bemangeredName
        }
        if (query.qrcByDoctor) {
          this.qrcByDoctor = query.qrcByDoctor
        }
        if (this.qrcByDoctor) {
          this.setUserInfo()
        } else {
          this.setDectorInfo()
        }
      },
      setUserInfo() {
        getUserInfoById(this.relationship.bemangeredId).then((res) => {
          this.info = res.data.data
        })
      },
      setDectorInfo() {
        getUserInfoById(this.relationship.mangerId).then((res) => {
          this.info = res.data.data
          this.headSculptureUrl = res.data.data.images
        })
      },
      addRelationship() {
        insertRelation(this.relationship).then((res) => {
          if (res.data.code === '200') {
            this.$toast('添加成功')
            location.reload([false])
          } else {
            this.$message.warning(res.data.msg)
          }
        })
      },
    },
  }
</script>
<style scoped>
  #QRCBind {
    color: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 200;
    background: #dbdbdb;
    text-align: center;
  }
  .box-card {
    width: 18rem;
    height: 12rem;
    margin: auto;
    margin-top: 1rem;
    background: #fff;
  }
  .el-icon-user-solid {
    color: #008bff;
  }
  .topBorder {
    width: 22rem;
    height: 26rem;
    background: #fff;
    margin: auto;
    margin-top: 6rem;
    padding-top: 2rem;
    border-radius: 8px;
  }
</style>
