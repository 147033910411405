<template>
  <div>
    <van-nav-bar :title="isEdit ? '编辑医院' : '添加医院'" left-arrow left-text="返回" @click-left="goBackMineView" />
    <div class="doctor-certification">
      <van-form ref="form" label-width="120px" :model="form" style="margin: 10px 0">
        <van-field
          v-model="address"
          is-link
          @click="areaShow = true"
          label="城市"
          placeholder="请选择城市"
          :rules="[{ required: true }]"
          readonly
        />
        <van-popup v-model="areaShow" round position="bottom">
          <van-area title="选择城市" :area-list="areaList" @cancel="areaShow = false" @confirm="areaSave" />
        </van-popup>
        <van-field v-model="form.structName" label="医院" placeholder="请输入医院名称" :rules="[{ required: true }]" />
        <van-field v-model="form.department" label="科室" placeholder="请输入科室名称" :rules="[{ required: true }]" />
        <div class="upload">
          <span class="picture">医院图片</span>
          <el-upload
            class="avatar-uploader"
            v-loading="loading"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-error="handleAvatarError"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </van-form>
      <van-button type="primary" block @click="submit">添加</van-button>
    </div>
  </div>
</template>

<script>
  import { areaList } from '@vant/area-data'
  import { addHospital, editHospital } from '../../../api/relationship'
  import { upload } from '../../../unitls/qiniutil'
  export default {
    data() {
      return {
        areaShow: false,
        areaList: areaList,
        address: '',
        form: {},
        imageUrl: '',
        isEdit: false,
        loading: false,
      }
    },
    created() {
      this.form.createId = JSON.parse(localStorage.getItem('appuser')).id
      if (this.$route.query.structId) {
        this.form = this.$route.query
        this.isEdit = true
        this.address = this.$route.query.province + this.$route.query.city + this.$route.query.region
        this.form.structName = this.$route.query.structName
        this.form.department = this.$route.query.department
        this.form.createId = this.$route.query.createId
      }
    },
    methods: {
      handleAvatarSuccess(res, file) {
        upload(file.raw, (url) => {
          console.log(url)
          this.imageUrl = url
          this.loading = false
        })
      },
      handleAvatarError(res, file) {
        upload(file.raw, (url) => {
          console.log(url)
          this.imageUrl = url
          this.loading = false
        })
      },
      beforeAvatarUpload(file) {
        this.loading = true
        // const isJPG = file.type === "image/jpeg";
        const isLt5M = file.size / 1024 / 1024 < 5

        // if (!isJPG) {
        //   this.$toast("上传头像图片只能是 JPG 格式!");
        // }
        if (!isLt5M) {
          this.$toast('上传图片大小不能超过 5MB!')
        }
        return isLt5M
      },
      // 保存城市选择
      areaSave(e) {
        console.log(e)
        this.address = e[0].name + e[1].name + e[2].name
        this.form.province = e[0].name
        this.form.city = e[1].name
        this.form.region = e[2].name
        this.areaShow = false
      },
      goBackMineView() {
        this.$router.go(-1)
      },
      submit() {
        this.$refs.form
          .validate()
          .then(() => {
            if (this.isEdit) {
              editHospital(this.form).then((data) => {
                if (data.data.code === '200') {
                  this.$message({
                    type: 'success',
                    message: '编辑医院成功',
                  })
                  this.$router.push('/promoter')
                } else {
                  this.$toast(data.data.msg)
                }
              })
            } else {
              this.form.imageUrl = this.imageUrl
              console.log(this.form.imageUrl, 'iiiiiii')
              addHospital(this.form).then((data) => {
                if (data.data.code === '200') {
                  this.$message({
                    type: 'success',
                    message: '添加医院成功',
                  })
                  this.$router.push('/promoter')
                } else {
                  this.$toast(data.data.msg)
                }
              })
            }
          })
          .catch(() => {
            this.$toast('请检查表单填写')
          })
      },
    },
  }
</script>

<style scoped lang="less">
  .doctor-certification {
    padding: 0 20px 20px 20px;
  }
  .van-cell--clickable {
    padding-right: 41px;
    .van-cell__value {
      span {
        color: #323233;
      }
    }
  }
  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  ::v-deep.van-popup {
    .van-ellipsis {
      line-height: 16px;
    }
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .upload {
    margin-top: 20px;
    margin-left: 14px;
    display: flex;
    span {
      margin-right: 40px;
      color: #646566;
      font-size: 14px;
    }
  }
  .van-loading {
    position: absolute;
    top: 74px;
    left: 74px;
  }
</style>
